import { Button, COLOR, Font } from '@components/atoms';
import { loadingSpinner } from '@components/atoms/lottie';
import BFlex from '@components/meraki-ui/BFlex';
import {
  managementMedicineTabList,
  MedicineCategoryType,
  medicineManagementCategoryConfig,
} from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import useMedicineUnitManagementModalHook from '@pages/wrapper/modals/medicineUnitManagementModal/hooks';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  selectMedicineManagementList,
  selectTelepharmacyCount_finished_pickup,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { cloneDeep } from 'lodash';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import AcceptedTelepharmacyCarousel from '../acceptedTelepharmacyTable/AcceptedTelepharmacyCarousel';
import * as Style from './index.style';
import { ListContent, ListItem, Pagination } from './sections';

const registerMedicineCountConfigInit: Record<string, number> = {
  전체: 0,
  피나스테리드: 0,
  '먹는 여드름약': 0,
  두타스테리드: 0,
  '바르는 여드름약': 0,
  '다이어트 주사': 0,
};

export const CREATED_MEDICINE_DIFFERENT_UNIT_DATE = '2024-09-12T20:00:00+09:00';

export const getManagementMedicineListCreatedAfterDate = (
  managementMedicine: GetManagedMedicineItemType,
) => {
  const targetDateKST = new Date(CREATED_MEDICINE_DIFFERENT_UNIT_DATE);
  const inputDateKST = new Date(managementMedicine.created);

  if (inputDateKST < targetDateKST) {
    return null;
  }

  return managementMedicine;
};

export const LISTITEM_FLEX = [
  { flex: 40, title: '' },
  { flex: 120, title: '보험코드' },
  { flex: 260, title: '약품명' },
  { flex: 110, title: '약가' },
  { flex: 100, title: '조제료' },
  { flex: 130, title: '예상 결제액' },
  { flex: 80, title: '재고 여부' },
  { flex: 190, title: '' },
];

function MedicineManagementTableV2() {
  const { openModal: openRegisterMedicineUnitModal, setSelectedCategory } =
    useMedicineUnitManagementModalHook();
  const managementMedicineList = useAppSelector(selectMedicineManagementList);

  const [selectedTabIndex, setSelectedTab] = useState(0);
  const [registerMedicineCountConfig, setRegisterMedicineCountConfig] =
    useState(registerMedicineCountConfigInit);
  const [filteredMedicineListByCreated, setFilteredMedicineListByCreated] =
    useState<GetManagedMedicineItemType[]>([]);

  const handleClickRegisterManagedMedicine = () => {
    openRegisterMedicineUnitModal();
    setSelectedCategory(managementMedicineTabList[selectedTabIndex].label);
  };

  useEffect(() => {
    if (managementMedicineList) {
      setFilteredMedicineListByCreated(
        managementMedicineList.filter((managementMedicine) =>
          getManagementMedicineListCreatedAfterDate(managementMedicine),
        ),
      );
    }
  }, [managementMedicineList]);

  useEffect(() => {
    if (filteredMedicineListByCreated) {
      const registerMedicineCountConfigResult = cloneDeep(
        registerMedicineCountConfigInit,
      );

      filteredMedicineListByCreated.forEach((item) => {
        const medicineName =
          medicineManagementCategoryConfig[
            item.pharmacy_medicine.generic_name_code
          ];

        registerMedicineCountConfigResult[medicineName] += 1;
        registerMedicineCountConfigResult['전체'] += 1;
      });

      setRegisterMedicineCountConfig(registerMedicineCountConfigResult);
    }
  }, [filteredMedicineListByCreated]);

  return (
    <Style.Container>
      <Style.ListArea>
        <Style.HeaderArea>
          <Style.TitleArea>
            <Font fontType="display2" color="fill/dark">
              비급여 의약품 관리
            </Font>
            <Style.WarningArea>
              <Font fontType="body2" color="state/distructive">
                새롭게 등록하신 의약품 가격은 9월 23일부터 적용될 예정입니다.
              </Font>
            </Style.WarningArea>
          </Style.TitleArea>
          <Button
            padding="16px 20px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/accent']}
            textColor={COLOR['fill/white']}
            title={
              <Font fontType="body1" color="fill/white">
                의약품 추가하기
              </Font>
            }
            onClick={handleClickRegisterManagedMedicine}
          />
        </Style.HeaderArea>

        <Style.OptionArea
          style={{ borderBottom: `1px solid ${COLOR['border/divider']}` }}
        >
          <Style.TabContainer>
            {managementMedicineTabList.map(({ id, label }) => {
              const isSelected = selectedTabIndex === id;
              const isEmpty = registerMedicineCountConfig[label] === 0;

              return (
                <Style.TabArea
                  key={id}
                  isActive={isSelected}
                  onClick={() => setSelectedTab(id)}
                >
                  <Style.TabContentArea>
                    <Style.TabLabelText isClickedMenu={isSelected}>
                      {label}
                    </Style.TabLabelText>
                    <Style.RegisterMedicineCount
                      isSelected={isSelected}
                      isEmpty={isEmpty}
                    >
                      <Font
                        fontType="body3_medium"
                        color={
                          isEmpty
                            ? 'greyscale6'
                            : isSelected
                            ? 'fill/white'
                            : 'fill/accent'
                        }
                      >
                        {registerMedicineCountConfig[label]}
                      </Font>
                    </Style.RegisterMedicineCount>
                  </Style.TabContentArea>
                </Style.TabArea>
              );
            })}
          </Style.TabContainer>
        </Style.OptionArea>

        <ManagementMedicineList
          selectedMedicineCategory={managementMedicineTabList[selectedTabIndex]}
        />
      </Style.ListArea>

      <BFlex width="1024px" padding="1.25rem 0 0 0">
        <AcceptedTelepharmacyCarousel pageType="confirmedTreatmentPickup" />
      </BFlex>
    </Style.Container>
  );
}

interface ManagementMedicineListProps {
  selectedMedicineCategory: MedicineCategoryType;
}

function ManagementMedicineList({
  selectedMedicineCategory,
}: ManagementMedicineListProps) {
  const { openModal: openRegisterMedicineUnitModal, setSelectedCategory } =
    useMedicineUnitManagementModalHook();

  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const [limit, setLimit] = useState<number>(200);
  const [isLoading, setIsLoading] = useState(true);
  const [
    filteredMedicineListByCreatedAndCategory,
    setFilteredMedicineListByCreatedAndCategory,
  ] = useState<GetManagedMedicineItemType[]>([]);
  const [isMedicineRegisterYet, setIsMedicineRegisterYet] = useState(false);
  const [filteredMedicineListByCreated, setFilteredMedicineListByCreated] =
    useState<GetManagedMedicineItemType[]>([]);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;
  const isShowAll = selectedMedicineCategory.codes.length === 0;

  const handleClickRegisterManagedMedicine = () => {
    openRegisterMedicineUnitModal();
    setSelectedCategory(selectedMedicineCategory.label);
  };

  useEffect(() => {
    if (pharmacist && pharmacist.pharmacy_id) {
      dispatch(
        getManagementMedicineList({
          id: pharmacist.pharmacy_id,
          offset: page * limit,
          limit,
        }),
      ).then(() => {
        setIsLoading(false);
      });
    }

    setIsLoading(true);

    return () => setIsLoading(false);
  }, [dispatch, page, limit, pharmacist]);

  useEffect(() => {
    if (filteredMedicineListByCreated) {
      const filterMedicineList = filteredMedicineListByCreated.filter((item) =>
        selectedMedicineCategory.codes.includes(
          item.pharmacy_medicine.generic_name_code,
        ),
      );

      setFilteredMedicineListByCreatedAndCategory(filterMedicineList);
      setIsMedicineRegisterYet(filterMedicineList.length === 0 && !isShowAll);
    }
  }, [filteredMedicineListByCreated, isShowAll, selectedMedicineCategory]);

  useEffect(() => {
    if (managementMedicineList) {
      setFilteredMedicineListByCreated(
        managementMedicineList.filter((managementMedicine) =>
          getManagementMedicineListCreatedAfterDate(managementMedicine),
        ),
      );
    }
  }, [managementMedicineList]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {LISTITEM_FLEX.map((item, index) => {
            return (
              <ListContent.Bold
                key={index.toString()}
                flex={item.flex}
                border="none"
              >
                <Font fontType="h5" color="fill/white">
                  {item.title}
                </Font>
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>

        {isLoading ? (
          <Style.LoaderArea>
            <Lottie
              animationData={loadingSpinner}
              style={{ width: 48, aspectRatio: 1 }}
              loop
            />
          </Style.LoaderArea>
        ) : isMedicineRegisterYet ||
          filteredMedicineListByCreated?.length === 0 ? (
          <Style.EmptyContainer>
            <Font fontType="body2" color="fill/medium" center>
              {`아직 등록된 의약품이 없어요`}
              <br />
              {`새로운 의약품을 등록하시겠어요?`}
            </Font>
            <Button
              width="80px"
              height="40px"
              borderRadius="8px"
              backgroundColor={COLOR['label/lightBlue']}
              textColor={COLOR['fill/white']}
              title={
                <Font fontType="body2_medium" color="fill/accent">
                  추가하기
                </Font>
              }
              onClick={handleClickRegisterManagedMedicine}
            />
          </Style.EmptyContainer>
        ) : isShowAll ? (
          filteredMedicineListByCreated.map((filteredMedicineByCreated) => {
            return (
              <ListItem
                item={filteredMedicineByCreated}
                key={filteredMedicineByCreated.id}
              />
            );
          })
        ) : (
          filteredMedicineListByCreatedAndCategory?.map(
            (filteredMedicineByCreatedAndCategory) => {
              return (
                <ListItem
                  item={filteredMedicineByCreatedAndCategory}
                  key={filteredMedicineByCreatedAndCategory.id}
                />
              );
            },
          )
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

export default MedicineManagementTableV2;
