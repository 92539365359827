import { Blank, Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { CallOutMessage } from '@components/molecules';
import printJS from 'print-js';
import { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { TelepharmacyType } from 'types/index';
import * as Style from './index.style';

function PrescriptionItemHeader({
  item,
}: {
  item: TelepharmacyType.TelepharmacyItemType;
}) {
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    if (item.tm && item.tm.prescription[0].image) {
      try {
        const pdfDocument = pdfjs.getDocument(item.tm.prescription[0].image);
        pdfDocument.promise.then((doc) => {
          setNumPages(doc._pdfInfo.numPages);
        });
      } catch (err) {
        console.log('PrescriptionItemHeader', err);
      }
    }
  }, [item]);

  const isMultiplePrescription = numPages && numPages > 1;

  return (
    <Style.ContentHeaderItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="display2" color="fill/black">
            처방전
            {isMultiplePrescription ? ` (총 ${numPages}장)` : ''}
          </Font>
        </Style.ContentHeaderTitle>
        <Style.ContentHeaderButton>
          <Button
            onClick={() => {
              window.open(item.tm?.prescription[0].image, '_blank');
            }}
            padding="9px 16px 9px 12px"
            borderColor={COLOR['border/outline']}
            borderRadius="8px"
            backgroundColor="fill/white"
            title={
              <Style.ButtonContainer>
                <SvgIcon
                  icon="receiptIcon"
                  width={16}
                  height={16}
                  color="fill/dark"
                />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body2" color="fill/dark">
                  처방전 원본
                </Font>
              </Style.ButtonContainer>
            }
          />
          <Blank appoint="Horizontal" size={12} />
          <Button
            onClick={() =>
              printJS({
                printable: item.tm?.prescription[0].image,
                type: 'pdf',
                showModal: true,
              })
            }
            padding="9px 16px 9px 12px"
            borderColor={COLOR['border/outline']}
            borderRadius="8px"
            backgroundColor="fill/white"
            title={
              <Style.ButtonContainer>
                <SvgIcon
                  icon="faxIcon"
                  color="fill/dark"
                  width={16}
                  height={16}
                />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body2" color="fill/dark">
                  프린트
                </Font>
              </Style.ButtonContainer>
            }
          />
        </Style.ContentHeaderButton>
      </Style.ContentHeaderArea>

      {item.is_substituted_patient === false && (
        <Style.AlertArea>
          <SvgIcon
            icon="infoIcon"
            width={22}
            height={22}
            color="state/distructive"
          />
          <Font fontType="body1_medium" color="state/distructive">
            대체조제 없이 처방전대로 조제가 필요한 환자입니다.
          </Font>
        </Style.AlertArea>
      )}

      {isMultiplePrescription && (
        <Style.ContentBodyArea>
          <CallOutMessage
            iconColor="fill/light"
            backgroundColor={COLOR.greyscale6}
          >
            <Font fontType="body1" color="fill/light">
              {`접수된 처방전이 총 ${numPages}장입니다.
            스크롤을 내려 모든 처방전을 확인해주세요.`}
            </Font>
          </CallOutMessage>
        </Style.ContentBodyArea>
      )}
    </Style.ContentHeaderItem>
  );
}

export default PrescriptionItemHeader;
