import { supporters } from '@functions/index';
import { useAppDispatch } from '@stores/hooks';
import { fetchLogin } from '@stores/userInformationStore';
import {
  trackFailLoginMixpanelEvents,
  trackSuccessLoginMixpanelEvents,
} from '@utils/mixpanel/login';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import { EmailLoginFormData } from '../login-form';

export const useLoginSubmitEmail = (
  updateErrorMessage: (message: string) => void,
) => {
  const { handleSubmit } = useFormContext<EmailLoginFormData>();
  const { logoutSupporter } = supporters;
  const [loginTryCount, setLoginTryCount] = useState(0);

  const dispatch = useAppDispatch();

  const login = useCallback(
    async (email: string, password: string) => {
      setLoginTryCount((prev) => prev + 1);
      await logoutSupporter.logout();
      const response = await dispatch(fetchLogin({ email, password }));

      const loginData = response.payload as {
        isLogin: boolean;
        message: string;
        userID: string;
        userType: string;
      };

      if (loginData.userType === 'USER_TYPE__PATIENT') {
        updateErrorMessage('해당 이메일로 가입된 계정이 없어요');
        return;
      }

      if (loginData?.isLogin) {
        trackSuccessLoginMixpanelEvents(loginData?.userID, loginData?.userType);
      } else {
        trackFailLoginMixpanelEvents(loginData?.message);

        if (loginTryCount >= 5) {
          updateErrorMessage(
            '계속 로그인이 실패하는 경우, 아이디/비밀번호 찾기를 해보세요',
          );
          return;
        }

        if (
          loginData.message ===
          '로그인에 실패했습니다. 아이디 및 비밀번호를 확인해주세요.'
        ) {
          updateErrorMessage('아이디 또는 비밀번호를 다시 확인해주세요');
        }
      }
    },
    [dispatch, logoutSupporter, updateErrorMessage, loginTryCount],
  );

  const onFormError: SubmitErrorHandler<EmailLoginFormData> = useCallback(
    (errors) => {
      console.log(errors);
    },
    [],
  );

  const onFormSubmit: SubmitHandler<EmailLoginFormData> = useCallback(
    async (data) => {
      const { EMAIL, PASSWORD } = data;

      const response = await login(EMAIL, PASSWORD);
    },
    [login],
  );

  const submit = debounce(() => {
    handleSubmit(onFormSubmit, onFormError)();
  }, 500);

  return { submit };
};
