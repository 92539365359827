import { MobileLayout } from '@mobile/components/layout/MobileLayout';
import AuthError from '@mobile/pages/AuthError';
import Error from '@mobile/pages/Error';
import MobileAuth from '@mobile/pages/MobileAuth';

import RequestList from '@mobile/pages/RequestList';
import TPAccept from '@mobile/pages/TPAccept';
import TPDetail from '@mobile/pages/TPDetail';
import TPReject from '@mobile/pages/TPReject';
import VerifyAuthentication from '@mobile/pages/VerifyAuthentication';
import loadChannelTalk from '@utils/channelTalk/loadChannelTalk';
import loadChannelTalkLogin from '@utils/channelTalk/loadChannelTalkLogin';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

function MobileRouter() {
  useEffect(() => {
    loadChannelTalkLogin();
    loadChannelTalk('', '', true);
  }, []);

  return (
    <MobileLayout>
      <Routes>
        <Route path="/" element={<MobileAuth />} />
        <Route path="/request" element={<RequestList />} />
        <Route path="/request/detail/:id" element={<TPDetail />} />
        <Route path="/request/accept/:id" element={<TPAccept />} />
        <Route path="/request/reject/:id" element={<TPReject />} />
        <Route path="/user-authentication" element={<VerifyAuthentication />} />
        <Route path="/auth-error" element={<AuthError />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </MobileLayout>
  );
}

export default MobileRouter;
