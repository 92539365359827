import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Font } from '@components/atoms';
import { PARCEL_LISTITEM_FLEX } from '@components/organisms/pickupTelepharmacyTable';
import ListContent from '@components/organisms/telepharmacyTable/components/listContent';
import {
  convertSubstitutedPatientStatus,
  convertSubstitutedPharmacyStatus,
} from '@components/organisms/utils/domain';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import { useState } from 'react';
import { MyDoctorModelType } from 'types/index.d';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;

function ListItem({
  item,
}: {
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isEmptyObject = (param: any) => {
    if (param === undefined) return true;
    return Object.keys(param).length === 0 && param.constructor === Object;
  };

  const isEmptyObjectPharmacyProductPayment = isEmptyObject(
    item.pharmacy_product_payment,
  );

  const commerceTotalAmount =
    !isEmptyObjectPharmacyProductPayment &&
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.total_amount
      ? item.pharmacy_product_payment.total_amount
      : 0;

  const isSubstitutedTextColorRed = item.is_substituted_patient === false;

  return (
    <Style.ListItem
      onMouseEnter={() => setIsButtonVisible(true)}
      onMouseLeave={() => setIsButtonVisible(false)}
      to={`/home/confirmedTreatment/ongoing-parcel/confirmedTreatmentOngoingParcelDetail?id=${item.id}`}
    >
      <ListContent.Element flex={PARCEL_LISTITEM_FLEX[0].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium" color="fill/dark">
            {item.delivery_info?.delivery_company_name || '운송장 발급 전'}
          </Font>

          <Font fontType="body2_medium" color="fill/dark">
            {item.delivery_info?.tracking_number &&
              `(${item.delivery_info?.tracking_number})`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={PARCEL_LISTITEM_FLEX[1].flex}>
        <Style.SubstitutedArea>
          <Font
            fontType="body2_medium"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
            }
          >
            {`환자 : ${convertSubstitutedPatientStatus(
              item.is_substituted_patient,
            )}`}
          </Font>
          <Font
            fontType="body2_medium"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
            }
          >
            {`약사 : ${
              item.auto_confirmed
                ? convertSubstitutedPatientStatus(item.is_substituted_patient)
                : convertSubstitutedPharmacyStatus(item.is_substituted_pharmacy)
            }`}
          </Font>
        </Style.SubstitutedArea>
      </ListContent.Element>

      <ListContent.Element flex={PARCEL_LISTITEM_FLEX[2].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">
            {item.patient?.name}
            {item.telemedicine ? (
              <>({item.telemedicine.telemedicine_hash})</>
            ) : null}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={PARCEL_LISTITEM_FLEX[3].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium" color="fill/dark">
            {phoneFormatter.prettyPhoneNumberFormatter(
              item.patient?.phone || item.requester?.phone,
            )}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={PARCEL_LISTITEM_FLEX[4].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">
            {`${priceFormatter.commaFormatter(
              item.amount ? String(item.amount) : '0',
            )}원`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={PARCEL_LISTITEM_FLEX[5].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">{item.address_string || '-'}</Font>
        </Style.ListItemLayout>
      </ListContent.Element>
    </Style.ListItem>
  );
}

export default ListItem;
