import { Button, COLOR, Font, ToggleButton } from '@components/atoms';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import useMedicineManagementCommonModalHook from '@pages/wrapper/modals/medicineManagmentCommonModal/hooks';
import useMedicineUnitManagementEditModal from '@pages/wrapper/modals/medicineUnitManagementEditModal/hooks';
import { myDoctorAPI } from '@services';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { getManagementMedicineList } from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { useState } from 'react';
import {
  medicineGenericCodeKoreanUnit,
  medicineManagementCategoryConfig,
} from '../../config/domain';
import { LISTITEM_FLEX } from '../../index';
import { ListContent } from '../index';
import * as Style from './index.style';

function ListItem({ item }: { item: GetManagedMedicineItemType }) {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const { openModal: openEditModal, setManagementMedicineUnitTargetMedicine } =
    useMedicineUnitManagementEditModal();
  const {
    openModal: openConfirmModal,
    setManagementMedicineModalType,
    setManagementMedicineTargetMedicine,
  } = useMedicineManagementCommonModalHook();
  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState<number>(200);
  const [isLoading, setIsLoading] = useState(false);
  const { priceFormatter } = formatters;

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const { pharmacy_medicine, unit, quantity, price } = item;
  const {
    generic_name_code,
    is_active,
    standard_code,
    standard_code_display,
    master_code,
    name,
  } = pharmacy_medicine;

  const handleClickChangeAutoOrderStatus = () => {
    if (is_active) {
      setManagementMedicineModalType('soldOut');
      setManagementMedicineTargetMedicine(item);
      openConfirmModal();
    } else {
      myDoctorAPI
        .patchManagementMedicine({
          ...item,
          id: item.id,
          is_deleted: false,
          pharmacy_medicine: {
            ...item.pharmacy_medicine,
            is_active: true,
          },
        })
        .then(() => {
          dispatch(
            getManagementMedicineList({
              id: pharmacist.pharmacy_id || 0,
              offset: page * limit,
              limit,
            }),
          );
        });
    }
  };

  const handleClickDeleteManagedMedicine = () => {
    setManagementMedicineModalType('delete');
    setManagementMedicineTargetMedicine(item);
    openConfirmModal();
  };

  return (
    <Style.ListItem>
      <ListContent.Regular flex={LISTITEM_FLEX[0].flex}>
        {medicineManagementCategoryConfig[generic_name_code] ||
          generic_name_code}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[1].flex}>
        {master_code}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[2].flex}>
        {name || (
          <Font fontType="body3_medium" color="state/distructive">
            {`의약품명이 비어 있습니다.\n삭제 후 다시 등록해 주세요.`}
          </Font>
        )}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[3].flex}>
        {quantity} {medicineGenericCodeKoreanUnit[generic_name_code]}
      </ListContent.Regular>

      <ListContent.Regular flex={LISTITEM_FLEX[4].flex}>
        {priceFormatter.commaFormatter(`${price}원`)}
      </ListContent.Regular>

      <ListContent.Element flex={LISTITEM_FLEX[5].flex}>
        <ToggleButton
          isActive={is_active}
          onClickToggle={handleClickChangeAutoOrderStatus}
        />
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[6].flex}>
        <Button
          width="60px"
          height="32px"
          backgroundColor={COLOR['fill/white']}
          borderColor={COLOR['border/outline']}
          title={
            <Font fontType="body2" color="fill/dark">
              삭제
            </Font>
          }
          onClick={handleClickDeleteManagedMedicine}
        />
      </ListContent.Element>
    </Style.ListItem>
  );
}

export default ListItem;
