import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import HeaderTab from '@components/organisms/headerTab';
import MedicineManagementTable from '@components/organisms/managementMedicineTable';

import MedicineManagementTableV2 from '@components/organisms/managementMedicineTableV2';
import PharmacyAutoConfirmed from '@components/organisms/pharmacyAutoConfirmed';
import CommonModal from '@pages/wrapper/modals/commonModal';
import useCommonModal from '@pages/wrapper/modals/commonModal/hooks';
import { useEffect, useState } from 'react';
import * as Style from './index.style';

function MedicineManagementPage() {
  const { openModal, closeModal } = useCommonModal({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabs = [
    {
      key: 0,
      title: '새로운 의약품 관리 (09.23 이후)',
      onClick: () => {
        setSelectedTabIndex(0);
      },
    },
    {
      key: 1,
      title: '기존 의약품 관리 (09.23 이전)',
      onClick: () => {
        setSelectedTabIndex(1);
      },
    },
  ];

  const handleClickViewPrevMedicineData = () => {
    closeModal();
    setSelectedTabIndex(1);
  };

  const handleClickViewCurrentMedicineData = () => {
    closeModal();
    setSelectedTabIndex(0);
  };

  useEffect(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      <CommonModal
        leftButtonCallback={handleClickViewPrevMedicineData}
        rightButtonCallback={handleClickViewCurrentMedicineData}
      />
      <Style.MedicineManagementPageContainer>
        <HeaderTab tabs={tabs} selectedTabIndex={selectedTabIndex} />
        <Style.MedicineManagementPageContent>
          {selectedTabIndex === 0 && (
            <>
              <Style.NoticeArea>
                <Style.NoticeContentArea>
                  <SvgIcon
                    icon="infoIcon"
                    color="state/distructive"
                    width={24}
                  />
                  <Style.NoticeTitleArea>
                    <Font fontType="h3" color="state/distructive">
                      새로운 비급여 가격을 등록해 주세요! (※ 기존 가격은 9월
                      23일부터 미노출됩니다.)
                    </Font>
                    <Font fontType="body2_medium" color="greyscale6">
                      {`약사님들 의견을 반영하여, 약가와 조제료를 별도로 등록하는 방식으로 가격 체계가 변경되었습니다.`}
                      <br />
                      {`기존 가격 확인이 필요하신 경우 [이전 의약품 정보 보기] 버튼을 눌러 확인해주세요.`}
                    </Font>
                  </Style.NoticeTitleArea>
                </Style.NoticeContentArea>
                <Style.NavigatePrevRegisterMedicineButtonArea>
                  <Button
                    padding="16px 20px"
                    backgroundColor={COLOR['fill/dark']}
                    borderRadius="12px"
                    title={
                      <Style.NavigatePrevRegisterMedicineButtonTextArea>
                        <Font fontType="body1_medium" color="fill/white">
                          이전 의약품 정보 보기
                        </Font>
                        <Style.ChevronDownIconRotate>
                          <SvgIcon
                            icon="chevronDown"
                            color="fill/white"
                            width={20}
                          />
                        </Style.ChevronDownIconRotate>
                      </Style.NavigatePrevRegisterMedicineButtonTextArea>
                    }
                    onClick={() => {
                      setSelectedTabIndex(1);
                    }}
                  />
                </Style.NavigatePrevRegisterMedicineButtonArea>
              </Style.NoticeArea>

              <Style.AutoConfirmedArea>
                <Style.AutoConfirmedHeaderArea>
                  <Style.AutoConfirmedTitleArea>
                    <Font fontType="display2" color="fill/dark">
                      비급여 조제 자동수락
                    </Font>
                  </Style.AutoConfirmedTitleArea>
                </Style.AutoConfirmedHeaderArea>
                <Style.AutoConfirmedContentArea>
                  <Font fontType="body2_medium" color="fill/medium">
                    새로운 비급여 가격 기준의 자동수락은 9월 23일 이후 사용할 수
                    있어요.
                  </Font>
                  <Font fontType="body2_medium" color="fill/medium">
                    적용 전까지는 기존 가격으로 자동수락이 진행됩니다.
                  </Font>
                </Style.AutoConfirmedContentArea>
              </Style.AutoConfirmedArea>

              <MedicineManagementTableV2 />
            </>
          )}
          {selectedTabIndex === 1 && (
            <>
              <Style.NoticeArea>
                <Style.NoticeContentArea>
                  <SvgIcon
                    icon="infoIcon"
                    color="state/distructive"
                    width={24}
                  />
                  <Style.NoticeTitleArea>
                    <Font fontType="h3" color="state/distructive">
                      비급여 의약품 관리 기능이 새로운 의약품 가격 체계로 개선될
                      예정입니다.
                    </Font>
                    <Font fontType="body2_medium" color="greyscale6">
                      {`9월 23일부터 의약품마다 약가와 조제료를 별도 설정 가능한 새로운 가격 체계가 적용될 예정입니다.`}
                      <br />
                      {`기존에 등록하셨던 의약품들의 가격을 미리 재등록 부탁드립니다.`}
                    </Font>
                  </Style.NoticeTitleArea>
                </Style.NoticeContentArea>
                <Style.NavigatePrevRegisterMedicineButtonArea>
                  <Button
                    padding="16px 20px"
                    backgroundColor={COLOR['fill/accent']}
                    borderRadius="12px"
                    title={
                      <Style.NavigatePrevRegisterMedicineButtonTextArea>
                        <Font fontType="body1_medium" color="fill/white">
                          새로운 의약품 가격 등록
                        </Font>
                        <Style.ChevronDownIconRotate>
                          <SvgIcon
                            icon="chevronDown"
                            color="fill/white"
                            width={20}
                          />
                        </Style.ChevronDownIconRotate>
                      </Style.NavigatePrevRegisterMedicineButtonTextArea>
                    }
                    onClick={() => {
                      setSelectedTabIndex(0);
                    }}
                  />
                </Style.NavigatePrevRegisterMedicineButtonArea>
              </Style.NoticeArea>
              <PharmacyAutoConfirmed />
              <MedicineManagementTable />
            </>
          )}
        </Style.MedicineManagementPageContent>
      </Style.MedicineManagementPageContainer>
    </>
  );
}

export default MedicineManagementPage;
