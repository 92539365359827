/* eslint-disable react-hooks/exhaustive-deps */
import { newLogo, notice } from '@assets/index';
import { Button, COLOR, Font } from '@components/atoms';
import CheckCircleIcon from '@components/atoms/images/CheckCircle';
import CloseCircle from '@components/atoms/images/CloseCircle';
import { commonHooks } from '@hooks/index';
import { myDoctorAPI } from '@services/myDoctor';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

function VerifyAuthentication() {
  const { useQuery } = commonHooks;
  const query = useQuery();
  const mobileToken = query.get('token') as string;
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean | undefined>(undefined);
  const [pharmacyName, setPharmacyName] = useState<string>('');
  const [requestTime, setRequestTime] = useState<string>('');

  console.log(123123, mobileToken);

  useEffect(() => {
    const validateToken = async () => {
      console.log(999, {
        token: mobileToken,
      });
      const response = await myDoctorAPI.getVerifyAuthentication({
        token: mobileToken,
      });
      console.log(123123123, response);

      if (response.serverStatus === 200) {
        setPharmacyName(response.pharmacy_name);
        setRequestTime(
          moment(response.expired_at)
            .subtract(5, 'minutes')
            .format('YYYY. MM. DD. HH:mm'),
        );
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    };
    validateToken();
  }, []);

  const disabled = false;

  const verifyAuthentication = async () => {
    try {
      const response = await myDoctorAPI.postVerifyAuthentication({
        token: mobileToken,
      });

      console.log(5555, response);
      if (response.serverStatus === 200) {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    } catch (e) {
      console.log('e from verifyPostAuthentication', e);
    }
  };

  const rejectAuthentication = async () => {
    try {
      const response = await myDoctorAPI.postVerifyAuthentication({
        token: mobileToken,
        rejected: true,
      });

      console.log(9999, response);

      if (response.serverStatus === 200) {
        setIsVerified(false);
      }
    } catch (e) {
      console.log('e from verifyPostAuthentication', e);
    }
  };

  if (!isValid) {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img alt="" src={notice} style={{ width: 88, height: 88 }} />
          <Font fontType="h2" color="fill/dark" center>
            {'해당 페이지는\n더 이상 유효하지 않습니다.'}
          </Font>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          backgroundColor: COLOR['fill/accent'],
          flexDirection: 'column',
          paddingTop: 40,
          paddingLeft: 20,
          paddingRight: 20,
          gap: 16,
        }}
      >
        <Font fontType="body2" color="fill/white">
          대한민국 대표 비대면 진료 플랫폼
        </Font>
        <img alt="" src={newLogo} style={{ width: '100%', height: 'auto' }} />
      </div>
      <div
        style={{
          display: 'flex',
          flex: 4,
          flexDirection: 'column',
          height: '100%',
        }}
      >
        {isVerified !== undefined && isVerified && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 24,
              flex: 1,
            }}
          >
            <CheckCircleIcon width={48} height={48} color="fill/accent" />

            <Font fontType="display2">본인 인증 완료</Font>
            <Font fontType="body1">
              {
                '본인 인증이 완료되었습니다.\n나만의닥터 약국 파트너 PC 웹페이지를 확인해주세요.'
              }
            </Font>
          </div>
        )}
        {isVerified !== undefined && !isVerified && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 24,
              flex: 1,
            }}
          >
            <CloseCircle width={48} height={48} color="fill/mediumLight" />
            <Font fontType="display2">본인 인증 실패</Font>
            <Font fontType="body1">
              {
                '본인 인증을 거절하셨습니다.\n계속해서 잘못된 요청이 온다면, 나만의닥터 고객센터로 연락해 주세요.'
              }
            </Font>
          </div>
        )}
        {isVerified === undefined && (
          <>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  padding: '24px 20px',
                }}
              >
                <Font fontType="display2">본인 인증 요청</Font>
                <Font fontType="body1" color="fill/dark">
                  {
                    '나만의닥터 약국 파트너 PC 웹페이지를 통해,\n본인 인증이 요청되었습니다.'
                  }
                </Font>
                <Font fontType="body1_medium">
                  본인 혹은 약국 직원에 의한 본인 인증 요청인가요?
                </Font>
              </div>
              <div style={{ paddingLeft: 20, paddingRight: 20, flex: 1 }}>
                <div
                  style={{
                    padding: 16,
                    border: `1px solid ${COLOR['border/outline']}`,
                    borderRadius: 8,
                    gap: 12,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 20,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Font fontType="body1" color="fill/medium">
                        약국명
                      </Font>
                    </div>
                    <div style={{ flex: 2 }}>
                      <Font fontType="body1_medium">{pharmacyName}</Font>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 20,
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Font fontType="body1" color="fill/medium">
                        요청 시간
                      </Font>
                    </div>
                    <div style={{ flex: 2 }}>
                      <Font fontType="body1_medium">{requestTime}</Font>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LoginButtonArea>
              <div
                style={{
                  flex: 1,
                }}
              >
                <Button
                  width="100%"
                  padding="16px 12px"
                  borderRadius="12px"
                  title={
                    <Font fontType="body1" color="fill/dark">
                      아니오
                    </Font>
                  }
                  onClick={() => {
                    rejectAuthentication();
                  }}
                  backgroundColor={COLOR['fill/white']}
                  borderColor={COLOR['border/outline']}
                />
              </div>
              <div style={{ flex: 2 }}>
                <Button
                  width="100%"
                  padding="16px 12px"
                  borderRadius="12px"
                  title={
                    <Font
                      fontType="body1"
                      color={disabled ? 'disabled/foreground' : 'fill/white'}
                    >
                      네, 맞아요
                    </Font>
                  }
                  onClick={() => {
                    verifyAuthentication();
                  }}
                  backgroundColor={
                    disabled
                      ? COLOR['disabled/background']
                      : COLOR['fill/accent']
                  }
                />
              </div>
            </LoginButtonArea>
          </>
        )}
        {isVerified !== undefined && !isVerified && (
          <a
            href="https://my-doctor.channel.io/home"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 28,
              textDecoration: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 16,
                paddingBottom: 16,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 12,
                border: `1px solid ${COLOR['border/outline']}`,
              }}
            >
              <Font fontType="body1">나만의닥터 고객센터 연결</Font>
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default VerifyAuthentication;

const LoginButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  padding: 0px 20px 28px 20px;
`;
