import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font, TextButton } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
  PageLoader,
  TextButtonWithTextArea,
} from '@components/molecules';
import { featureHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_cancelTelepharmacyModal,
  selectCancelTelepharmacyModalItem,
  selectCancelTelepharmacyModalVisible,
} from '@stores/modalStore/cancelTelepharmacyModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import { FormEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Style from './index.style';

function CancelTelepharmacyModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { useFetchCancelReason } = featureHooks;
  const cancelReasonList = useFetchCancelReason({ type: 'TP' });
  const [selectedId, setSelectedId] = useState<number>();
  const isOpen = useAppSelector(selectCancelTelepharmacyModalVisible);
  const item = useAppSelector(selectCancelTelepharmacyModalItem);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const { pathname } = useLocation();

  const [cancelReason, setCancelReason] = useState('');
  const [prescriptionProblemReason, setPrescriptionProblemReason] =
    useState('');
  const [extraReason, setExtraReason] = useState('');

  const selectCancel = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setCancelReason(text);
    setPrescriptionProblemReason('');
    setExtraReason('');
  };

  const selectPrescriptionProblemReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setCancelReason('');
    setPrescriptionProblemReason(text);
    setExtraReason('');
  };

  const selectExtraCancel = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setCancelReason('');
    setPrescriptionProblemReason('');
    setExtraReason(text);
  };

  const onChangePrescriptionProblemReason = (
    e: FormEvent<HTMLTextAreaElement>,
  ) => {
    setPrescriptionProblemReason(e.currentTarget.value);
  };

  const onChangeExtraReason = (e: FormEvent<HTMLTextAreaElement>) => {
    setExtraReason(e.currentTarget.value);
  };

  const closeModal = () => {
    setIsPageLoading(false);
    dispatch(closeModal_cancelTelepharmacyModal());
    setCancelReason('');
    setPrescriptionProblemReason('');
    setExtraReason('');
    setSelectedId(undefined);
  };

  const cancel = () => {
    if (window.confirm(`취소요청을 보내시겠습니까?`)) {
      setIsPageLoading(true);

      let reason;
      if (selectedId === 100) {
        reason = extraReason;
      } else if (selectedId === 101) {
        reason = `처방전에 문제가 있어 병원에 다시 확인이 필요합니다. ${prescriptionProblemReason}`;
      } else {
        reason = cancelReason;
      }
      const id = item.id ? item.id : 0;
      myDoctorAPI
        .postCancelTP({ cancel_reason: reason }, id)
        // .postCallCS({
        //   title: `조제 취소요청`,
        //   text: reason,
        //   category: 'telepharmacy',
        //   type: 'TPProcess',
        //   object_id: id,
        // })
        .then((responseJSON) => {
          console.log(responseJSON);
          // if (responseJSON.id) {
          alert('취소요청을 보냈습니다.');
          closeModal();
          dispatch(getTelepharmacyCount()).then(() =>
            setTimeout(() => navigate('/home/waitForDispense'), 700),
          );
          // } else {
          //   alert('요청에 실패했습니다.');
          // }
        })
        .finally(() => setIsPageLoading(false));
    }
  };

  const buttonVisible =
    cancelReason.length >= 1 ||
    prescriptionProblemReason.length >= 1 ||
    extraReason.length >= 1;

  const isDirectCancel = pathname.includes('confirmedDetail');

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="display2">
              {isDirectCancel ? '조제 및 결제취소' : '취소요청(고객센터 연결)'}
            </Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Style.DescriptionArea>
              <CallOutMessage>
                <Font fontType="body1">
                  확정된 조제는 나만의닥터 고객센터에서 확인 후 최종적으로 취소
                  처리될 예정입니다.
                </Font>
              </CallOutMessage>
            </Style.DescriptionArea>
            {cancelReasonList.map((cancelReasonItem) => (
              <Style.TextButtonArea key={cancelReasonItem.id}>
                <TextButton
                  isSelected={selectedId === cancelReasonItem.id}
                  title={cancelReasonItem.text}
                  onClick={() =>
                    selectCancel(cancelReasonItem.id, cancelReasonItem.text)
                  }
                  key={String(cancelReasonItem.id)}
                />
              </Style.TextButtonArea>
            ))}
            <Style.TextButtonArea>
              <TextButtonWithTextArea
                isSelected={selectedId === 101}
                title="처방전에 문제가 있어 병원에 다시 확인이 필요합니다."
                onClick={() =>
                  selectPrescriptionProblemReject(
                    101,
                    prescriptionProblemReason,
                  )
                }
                key={String(101)}
                value={prescriptionProblemReason}
                onChange={onChangePrescriptionProblemReason}
                type="text"
                placeholder="(예시)&#13;&#10;ㆍ 주민등록번호가 이상합니다.&#13;&#10;ㆍ 처방전 파일이 비어있습니다.&#13;&#10;ㆍ 다른 환자의 처방전입니다."
                height={120}
                fontSize="16px"
              />
            </Style.TextButtonArea>
            <Style.TextButtonArea style={{ marginBottom: '0' }}>
              <TextButtonWithTextArea
                isSelected={selectedId === 100}
                title="기타"
                onClick={() => selectExtraCancel(100, extraReason)}
                key={String(100)}
                value={extraReason}
                onChange={onChangeExtraReason}
                type="text"
                placeholder="진료 거절 사유를 입력해주세요."
                height={120}
                fontSize="16px"
              />
            </Style.TextButtonArea>
          </>
        }
        modalFooter={
          <Button
            width="160px"
            padding="16px 12px"
            onClick={() => cancel()}
            disableColor={COLOR['fill/medium']}
            backgroundColor={COLOR['fill/accent']}
            visible={buttonVisible}
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.Check2 color={COLOR['fill/white']} />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body2" color="fill/white">
                  취소처리 요청하기
                </Font>
              </Style.ButtonContainer>
            }
          />
        }
      />
    </>
  );
}

export default CancelTelepharmacyModal;
