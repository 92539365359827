import { COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { loadingSpinner } from '@components/atoms/lottie';
import BFlex from '@components/meraki-ui/BFlex';
import { managementMedicineTabList } from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  selectMedicineManagementList,
  selectTelepharmacyCount_finished_pickup,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import AcceptedTelepharmacyCarousel from '../acceptedTelepharmacyTable/AcceptedTelepharmacyCarousel';
import { CREATED_MEDICINE_DIFFERENT_UNIT_DATE } from '../managementMedicineTableV2';
import * as Style from './index.style';
import { ListContent, ListItem, Pagination } from './sections';

export const LISTITEM_FLEX = [
  { flex: 140, title: '카테고리' },
  { flex: 120, title: '보험코드' },
  { flex: 360, title: '약품명' },
  { flex: 100, title: '처방 단위' },
  { flex: 140, title: '가격' },
  { flex: 110, title: '재고 여부' },
  { flex: 100, title: '' },
];

export const getManagementMedicineListCreatedBeforeDate = (
  managementMedicine: GetManagedMedicineItemType,
) => {
  const targetDateKST = new Date(CREATED_MEDICINE_DIFFERENT_UNIT_DATE);
  const inputDateKST = new Date(managementMedicine.created);

  if (inputDateKST >= targetDateKST) {
    return null;
  }

  return managementMedicine;
};

function MedicineManagementTable() {
  const [selectedTabIndex, setSelectedTab] = useState(0);

  return (
    <Style.Container>
      <Style.ListArea>
        <Style.HeaderArea>
          <Style.TitleArea>
            <Font fontType="display2" color="fill/dark">
              의약품 관리
            </Font>
            <Style.WarningArea>
              <SvgIcon
                icon="infoIcon"
                color="state/distructive"
                width={16}
                height={16}
              />
              <Font fontType="body2" color="state/distructive">
                의약품 가격은 사입가가 아닌 조제료가 포함된 판매가로 등록해
                주세요.
              </Font>
            </Style.WarningArea>
          </Style.TitleArea>
        </Style.HeaderArea>

        <Style.OptionArea
          style={{ borderBottom: `1px solid ${COLOR['border/divider']}` }}
        >
          <Style.TabContainer>
            {managementMedicineTabList.map(({ id, label }) => (
              <Style.TabArea
                key={id}
                isActive={selectedTabIndex === id}
                onClick={() => setSelectedTab(id)}
              >
                {label}
              </Style.TabArea>
            ))}
          </Style.TabContainer>
        </Style.OptionArea>

        <ManagementMedicineList
          showCodes={managementMedicineTabList[selectedTabIndex].codes}
        />
      </Style.ListArea>

      <BFlex width="1024px" padding="1.25rem 0 0 0">
        <AcceptedTelepharmacyCarousel pageType="confirmedTreatmentPickup" />
      </BFlex>
    </Style.Container>
  );
}

interface ManagementMedicineListProps {
  showCodes: string[];
}

function ManagementMedicineList({ showCodes }: ManagementMedicineListProps) {
  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const telepharmacyCount = useAppSelector(
    selectTelepharmacyCount_finished_pickup,
  );

  const [limit, setLimit] = useState<number>(200);
  const [isLoading, setIsLoading] = useState(true);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;
  const isShowAll = showCodes.length === 0;

  useEffect(() => {
    if (pharmacist && pharmacist.pharmacy_id) {
      dispatch(
        getManagementMedicineList({
          id: pharmacist.pharmacy_id,
          offset: page * limit,
          limit,
        }),
      ).then(() => {
        setIsLoading(false);
      });
    }

    setIsLoading(true);

    return () => setIsLoading(false);
  }, [dispatch, page, limit, pharmacist]);

  const [isMedicineRegisterYet, setIsMedicineRegisterYet] = useState(false);
  const [
    filteredMedicineListByCreatedAndCategory,
    setFilteredMedicineListByCreatedAndCategory,
  ] = useState<GetManagedMedicineItemType[]>([]);
  const [filteredMedicineListByCreated, setFilteredMedicineListByCreated] =
    useState<GetManagedMedicineItemType[]>([]);

  useEffect(() => {
    if (filteredMedicineListByCreated) {
      const filterMedicineList = filteredMedicineListByCreated.filter((item) =>
        showCodes.includes(item.pharmacy_medicine.generic_name_code),
      );

      setFilteredMedicineListByCreatedAndCategory(filterMedicineList);
      setIsMedicineRegisterYet(filterMedicineList.length === 0 && !isShowAll);
    }
  }, [filteredMedicineListByCreated, isShowAll, showCodes]);

  useEffect(() => {
    if (managementMedicineList) {
      setFilteredMedicineListByCreated(
        managementMedicineList.filter((managementMedicine) =>
          getManagementMedicineListCreatedBeforeDate(managementMedicine),
        ),
      );
    }
  }, [managementMedicineList]);

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {LISTITEM_FLEX.map((item, index) => {
            return (
              <ListContent.Bold
                key={index.toString()}
                flex={item.flex}
                border="none"
              >
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>

        {isLoading ? (
          <Style.LoaderArea>
            <Lottie
              animationData={loadingSpinner}
              style={{ width: 48, aspectRatio: 1 }}
              loop
            />
          </Style.LoaderArea>
        ) : isMedicineRegisterYet ||
          filteredMedicineListByCreated?.length === 0 ? (
          <Style.EmptyContainer>
            <Font fontType="body2" color="fill/medium" center>
              9월 23일까지 일시적으로 환자에게 안내할 <br />
              의약품 가격 등록이 필요하시면 고객센터로 연락해주세요
            </Font>
          </Style.EmptyContainer>
        ) : isShowAll ? (
          filteredMedicineListByCreated?.map((item) => {
            return <ListItem item={item} key={item.id} />;
          })
        ) : (
          filteredMedicineListByCreatedAndCategory?.map((item) => {
            return <ListItem item={item} key={item.id} />;
          })
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

export default MedicineManagementTable;
