import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function ChevronDown({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5892 7.74408C15.9147 8.06951 15.9147 8.59715 15.5892 8.92259L15 8.33333L14.4107 7.74408C14.7362 7.41864 15.2638 7.41864 15.5892 7.74408ZM9.99998 12.1548L14.4107 7.74408C14.4107 7.74405 14.4107 7.74408 15 8.33333C15.5892 8.92259 15.5893 8.92256 15.5892 8.92259L10.5892 13.9226C10.2638 14.248 9.73616 14.248 9.41072 13.9226L4.41072 8.92259C4.08529 8.59715 4.08529 8.06951 4.41072 7.74408C4.73616 7.41864 5.2638 7.41864 5.58923 7.74408L9.99998 12.1548Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default ChevronDown;
