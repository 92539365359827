import { namanLogoBlackSmall } from '@assets';
import { Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { pathnameList, styleVariables } from '@constants';
import EmptyVATModal from '@pages/wrapper/modals/EmptyVATModal';
import VATModal from '@pages/wrapper/modals/VATModal';
import { useAppSelector } from '@stores/hooks';
import {
  selectPharmacyWorkingTimeData,
  selectUserInformationPharmacistData,
} from '@stores/userInformationStore';
import { getBrowserInfo } from '@utils/browser';
import { isPharmacyOpenNow } from '@utils/domain';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { GlobalNavBarTemplateType } from './index.d';
import * as Style from './index.style';

const { BREAK_POINTS } = styleVariables;
const { PHARMACIST_ADMIN_PATHNAME } = pathnameList;

function GlobalNavBarTemplate({
  windowWidth,
  requestedCount,
  acceptedCount,
  confirmedCount,
  preConfirmedCount,
  finishedPickupCount,
  finishedQuickCount,
  finishedParcelCount,
  isOpen,
  openGNB,
  closeGNB,
  handleGNB,
  userData,
  pharmacistData,
  pathname,
  openCustomerCenterModal,
  openCompanyInfoModal,
  onLogout,
  isDescriptionDropdownClicked,
  onDescriptionDropdownClicked,
  navigateToPage,
  completedParcelCount,
}: GlobalNavBarTemplateType) {
  const [isVATModal, setIsVATModal] = useState(false);
  const pharmacist_working_time = useAppSelector(selectPharmacyWorkingTimeData);
  const { has_payment_permission } = useAppSelector(
    selectUserInformationPharmacistData,
  );

  const isPharmacyOpen = useMemo(() => {
    if (pharmacist_working_time && pharmacist_working_time.length > 0) {
      return isPharmacyOpenNow(pharmacist_working_time);
    }
    return true;
  }, [pharmacist_working_time]);

  const getNotificationCount = (value: number | undefined) => {
    if (isNil(value) || value < 0) {
      return 0;
    }
    if (value > 99) {
      return '99+';
    }

    return value;
  };

  // TODO 리펙토링
  const treatmentLinksArray = [
    {
      id: 0,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <Font
            fontType="h4"
            color={isClickedMenu ? 'fill/black' : 'greyscale4'}
          >
            접수대기
          </Font>
        );
      },
      notification: getNotificationCount(requestedCount),
      to: 'newReservation/requested',
    },
    {
      id: 1,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <Font
            fontType="h4"
            color={isClickedMenu ? 'fill/black' : 'greyscale4'}
          >
            결제대기
          </Font>
        );
      },
      notification: getNotificationCount(acceptedCount),
      to: 'newReservation/accepted',
    },
    {
      id: 2,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <Font
            fontType="h4"
            color={isClickedMenu ? 'fill/black' : 'greyscale4'}
          >
            조제대기
          </Font>
        );
      },
      notification: getNotificationCount(confirmedCount),
      to: 'waitForDispense',
    },
    {
      id: 3,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <Font
            fontType="h4"
            color={isClickedMenu ? 'fill/black' : 'greyscale4'}
          >
            수거대기
          </Font>
        );
      },
      notification: getNotificationCount(completedParcelCount),
      to: 'confirmedTreatment',
    },
    {
      id: 4,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <Font
            fontType="h4"
            color={isClickedMenu ? 'fill/black' : 'greyscale4'}
          >
            이전 내역
          </Font>
        );
      },
      notification: null,
      to: 'completeTreatment',
    },
    {
      id: 'divider',
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return null;
      },
      notification: null,
      to: '',
    },
    {
      id: 5,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <>
            <Font
              fontType="h4"
              color={isClickedMenu ? 'fill/black' : 'greyscale4'}
            >
              의약품 관리
            </Font>
            <Style.GNBLinkNotice isClickedMenu={isClickedMenu}>
              <Font
                fontType="body3_medium"
                color={isClickedMenu ? 'fill/white' : 'blue500'}
                hoverColor="blue500"
              >
                New
              </Font>
            </Style.GNBLinkNotice>
          </>
        );
      },
      notification: null,
      to: 'medicineManagement',
    },
    {
      id: 6,
      // eslint-disable-next-line react/no-unstable-nested-components
      label: (isClickedMenu: boolean) => {
        return (
          <Font
            fontType="h4"
            color={isClickedMenu ? 'fill/black' : 'greyscale4'}
          >
            영업시간 관리
          </Font>
        );
      },

      notification: null,
      to: 'timeManagement',
    },
  ];

  // TODO 리펙
  const dropdownListArray = [
    {
      id: 0,
      label: (
        <Font fontType="body1" color="fill/dark">
          의약품 관리
        </Font>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        navigateToPage(`/home/medicineManagement`);
      },

      isDivider: false,
    },
    {
      id: 1,
      label: (
        <Font fontType="body1" color="fill/dark">
          영업시간 관리
        </Font>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        navigateToPage(`/home/timeManagement`);
      },
      isDivider: true,
    },
    {
      id: 2,
      label: (
        <Style.DropdownNoticeArea>
          <Font fontType="body1" color="fill/dark">
            공지사항
          </Font>
        </Style.DropdownNoticeArea>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        window.open('https://mydoctor-notice-p.oopy.io/');
      },
      isDivider: false,
    },
    {
      id: 7,
      label: (
        <Style.DropdownNoticeArea>
          <Font fontType="body1" color="fill/dark">
            PC 알림 설정법
          </Font>
          <Style.DropdownNotice>
            <Font fontType="body3_medium" color="fill/white">
              New
            </Font>
          </Style.DropdownNotice>
        </Style.DropdownNoticeArea>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        const userBrowserInfo = getBrowserInfo();
        if (!window) return;

        if (userBrowserInfo === 'Chrome') {
          window.open(
            'https://merakiplace.notion.site/Chrome-17d7f40596d2447e8f557e56800863df',
            '_blank',
          );
        } else if (userBrowserInfo === 'Edge') {
          window.open(
            'https://merakiplace.notion.site/Edge-7ebedfa4378b41988602e3e8a2f710cb',
            '_blank',
          );
        } else {
          window.open(
            'https://merakiplace.notion.site/Edge-7ebedfa4378b41988602e3e8a2f710cb',
            '_blank',
          );
        }
      },
      isDivider: false,
    },
    {
      id: 3,
      label: (
        <Font fontType="body1" color="fill/dark">
          고객센터
        </Font>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        openCustomerCenterModal();
      },
      isDivider: true,
    },
    {
      id: 4,
      label: (
        <Font fontType="body1" color="fill/dark">
          나만의닥터 이용약관
        </Font>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        window.open('/termsOfService', '_blank');
      },
      isDivider: false,
    },
    {
      id: 5,
      label: (
        <Font fontType="body1" color="fill/dark">
          나만의닥터 사업자 정보
        </Font>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        openCompanyInfoModal();
      },
      isDivider: true,
    },
    {
      id: 6,
      label: (
        <Font fontType="body1" color="fill/dark">
          로그아웃
        </Font>
      ),
      clickEvent: () => {
        onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
        onLogout();
      },
      isDivider: false,
    },
  ];

  return (
    <>
      <Style.GNBTopBar>
        <Style.GNBTopBarArea>
          <Style.GNBLogoArea>
            <Style.GNBLogo
              src={namanLogoBlackSmall}
              alt="logo"
              onClick={() => {
                navigateToPage(
                  PHARMACIST_ADMIN_PATHNAME.NEW_RESERVATION_REQUESTED,
                );
                closeGNB();
              }}
            />
          </Style.GNBLogoArea>
          <Style.GNBLinkContainerArea>
            {treatmentLinksArray.map(({ id, label, notification, to }) => {
              const isClickedMenu = pathname.includes(to);
              const isNotificationEmpty = notification === 0;

              if (id === 'divider') {
                return <Style.GNBDivider key="divider" />;
              }
              return (
                <Style.GNBLinkContainer key={id}>
                  <Style.GNBLinkArea
                    isActive={isClickedMenu}
                    onClick={() => {
                      navigateToPage(`/home/${to}`);
                      if (id === 3) {
                        // openGNB();
                      } else {
                        closeGNB();
                      }
                    }}
                  >
                    {label(isClickedMenu)}
                    {!isNil(notification) && (
                      <Style.GNBLinkNotification
                        isClickedMenu={isClickedMenu}
                        isNotificationEmpty={isNotificationEmpty}
                      >
                        <Font
                          noWhiteSpace
                          fontType="body3_medium"
                          color={`${
                            isNotificationEmpty
                              ? 'greyscale6'
                              : isClickedMenu
                              ? 'fill/white'
                              : 'fill/accent'
                          }`}
                        >
                          {notification}
                        </Font>
                      </Style.GNBLinkNotification>
                    )}
                  </Style.GNBLinkArea>
                </Style.GNBLinkContainer>
              );
            })}

            {windowWidth && windowWidth > BREAK_POINTS.size1125 && (
              <>
                <Style.GNBLinkContainer>
                  <Style.GNBLinkArea
                    isActive={false}
                    onClick={() => {
                      window.open('https://mydoctor-notice-p.oopy.io/');
                    }}
                  >
                    <Style.GNBLabelText isClickedMenu={false}>
                      공지사항
                    </Style.GNBLabelText>
                  </Style.GNBLinkArea>
                </Style.GNBLinkContainer>

                <Style.GNBLinkContainer>
                  <Style.GNBLinkArea
                    isActive={false}
                    onClick={() => {
                      setIsVATModal(true);
                    }}
                  >
                    <Style.GNBLabelText isClickedMenu={false}>
                      부가세 신고
                    </Style.GNBLabelText>
                  </Style.GNBLinkArea>
                </Style.GNBLinkContainer>
              </>
            )}
          </Style.GNBLinkContainerArea>
        </Style.GNBTopBarArea>

        <Style.GNBDescriptionArea>
          {windowWidth && windowWidth < BREAK_POINTS.size1280 ? (
            <Style.GNBStatusButtonArea
              isDescriptionDropdownClicked={isDescriptionDropdownClicked}
              onClick={(event) => {
                event.stopPropagation();
                onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
              }}
              onBlur={() => {
                onDescriptionDropdownClicked(false);
              }}
            >
              <Style.GNBStatus isOpen={isPharmacyOpen}>
                <Font fontType="body3" color="fill/white">
                  {isPharmacyOpen ? 'ON' : 'OFF'}
                </Font>
              </Style.GNBStatus>
              <Style.GNBIconArea
                isDescriptionDropdownClicked={isDescriptionDropdownClicked}
              >
                <SvgIcon
                  icon="chevronDown"
                  width={20}
                  height={20}
                  color={
                    isDescriptionDropdownClicked ? 'fill/accent' : 'fill/black'
                  }
                />
              </Style.GNBIconArea>
            </Style.GNBStatusButtonArea>
          ) : (
            <Style.GNBDescriptionButtonContainer
              isDescriptionDropdownClicked={isDescriptionDropdownClicked}
              onClick={(event) => {
                event.stopPropagation();
                onDescriptionDropdownClicked(!isDescriptionDropdownClicked);
              }}
              onBlur={() => {
                onDescriptionDropdownClicked(false);
              }}
            >
              <Style.GNBContentArea>
                <Style.GNBStatusArea>
                  <Style.GNBStatus isOpen={isPharmacyOpen}>
                    <Font fontType="body3" color="fill/white">
                      {isPharmacyOpen ? 'ON' : 'OFF'}
                    </Font>
                  </Style.GNBStatus>
                </Style.GNBStatusArea>
                <Style.GNBTextArea>
                  <Style.GNBTopTextArea>
                    <Font
                      fontType="h5"
                      color={
                        isDescriptionDropdownClicked
                          ? 'fill/accent'
                          : 'fill/black'
                      }
                    >{`${
                      userData.name && userData.name.length > 8
                        ? `${userData.name?.slice(0, 8)}`
                        : userData.name
                    } 선생님`}</Font>
                  </Style.GNBTopTextArea>
                  <Style.GNBBottomTextArea>
                    <Font fontType="body3" color="fill/medium">
                      {pharmacistData.pharmacy_name &&
                      pharmacistData.pharmacy_name.length > 14
                        ? `${pharmacistData.pharmacy_name?.slice(0, 11)}...`
                        : pharmacistData.pharmacy_name}
                    </Font>
                  </Style.GNBBottomTextArea>
                </Style.GNBTextArea>
              </Style.GNBContentArea>

              <Style.GNBIconArea
                isDescriptionDropdownClicked={isDescriptionDropdownClicked}
              >
                <SvgIcon
                  icon="chevronDown"
                  width={20}
                  height={20}
                  color={
                    isDescriptionDropdownClicked ? 'fill/accent' : 'fill/black'
                  }
                />
              </Style.GNBIconArea>
            </Style.GNBDescriptionButtonContainer>
          )}
          {isDescriptionDropdownClicked && (
            <Style.GNBDescriptionDropdownList
              onMouseDown={(event) => event.preventDefault()}
            >
              {dropdownListArray.map(({ id, label, isDivider, clickEvent }) => (
                <Style.GNBDescriptionDropdownItemArea
                  key={id}
                  isDivider={isDivider}
                  onClick={clickEvent}
                >
                  {label}
                </Style.GNBDescriptionDropdownItemArea>
              ))}
            </Style.GNBDescriptionDropdownList>
          )}
        </Style.GNBDescriptionArea>
      </Style.GNBTopBar>

      {isVATModal &&
        (has_payment_permission ? (
          <VATModal
            open={isVATModal}
            onClose={() => setIsVATModal(false)}
            callOutMessage={`부가세 신고 자료 조회기간을 설정해주세요.\n조회 시작일은 2022년 9월 17일일부터 가능합니다.`}
            title="부가세 신고 자료 안내"
            VATDescription="상세내역은 파일을 다운로드 후 확인해주세요."
          />
        ) : (
          <EmptyVATModal
            open={isVATModal}
            onClose={() => setIsVATModal(false)}
            callOutMessage={`부가세 신고 자료 조회는 정산 이메일과 동일한 이메일 계정에서만 조회가 가능합니다.
조회에 문제가 있는 경우, 고객센터로 연락 바랍니다.`}
            title="부가세 신고 자료 조회 불가"
          />
        ))}
    </>
  );
}

export default GlobalNavBarTemplate;
