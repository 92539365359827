import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ModalTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CloseButtonArea = styled.div`
  cursor: pointer;
`;

export const ModalBodyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLOR['border/divider']};
`;

export const DotIconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 10px;
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ModalFooterArea = styled.div`
  display: flex;
  gap: 12px;
`;

export const MedicineWarningText = styled.span`
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${COLOR['state/distructive']};
`;

export const MedicineText = styled.span`
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${COLOR['fill/accent']};
`;
