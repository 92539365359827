import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import ListContent from '@components/organisms/telepharmacyTable/components/listContent';
import {
  convertSubstitutedPatientStatus,
  convertSubstitutedPharmacyStatus,
} from '@components/organisms/utils/domain';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import useFinishTelepharmacyModalHook from '@pages/wrapper/modals/finishTelepharmacyModal/hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch } from '@stores/hooks';
import { openModal_finishTelepharmacyModal } from '@stores/modalStore/finishTelepharmacyModal';
import moment from 'moment';
import printJS from 'print-js';
import { MouseEvent } from 'react';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;

const NO_PASSWORD_CASE_TEXT = '비밀번호 없이 출입 가능해요';

function ListItem({
  item,
}: {
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const dispatch = useAppDispatch();

  const { finishTelepharmacy } = useFinishTelepharmacyModalHook();

  let deliveryType: string;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문수령';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const confirmed_waitingTime = parseInt(
    String((moment().unix() - moment(item.accept_time).unix()) / 60 + 1),
    10,
  );

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isSubstitutedTextColorRed = item.is_substituted_patient === false;

  return (
    <Style.ListItem to={`/home/waitForDispense/confirmedDetail?id=${item.id}`}>
      <ListContent.Element flex={LISTITEM_FLEX[0].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium" color="fill/dark">
            {confirmed_waitingTime}분 대기
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[1].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">{deliveryType}</Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[2].flex}>
        <Style.SubstitutedArea>
          <Font
            fontType="body2_medium"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
            }
          >
            {`환자 : ${convertSubstitutedPatientStatus(
              item.is_substituted_patient,
            )}`}
          </Font>
          <Font
            fontType="body2_medium"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
            }
          >
            {`약사 : ${
              item.auto_confirmed
                ? convertSubstitutedPatientStatus(item.is_substituted_patient)
                : convertSubstitutedPharmacyStatus(item.is_substituted_pharmacy)
            }`}
          </Font>
        </Style.SubstitutedArea>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[3].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">
            {`${item.patient?.name}(${item.telemedicine?.telemedicine_hash})`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[4].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">
            {phoneFormatter.prettyPhoneNumberFormatter(
              item.patient?.phone || item.requester?.phone,
            ) || '-'}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[5].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium">
            {`${priceFormatter.commaFormatter(
              item.amount ? `${item.amount?.toString()}원` : '-',
            )}`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <Style.ListButtonItem
        flex={LISTITEM_FLEX[6].flex}
        flexDirection="row"
        border=""
      >
        {item.has_subscription ? (
          <Button
            padding="5px 12px 5px 9px"
            borderColor={COLOR['border/outline']}
            backgroundColor={COLOR['fill/white']}
            borderRadius="4px"
            title={
              <>
                <Style.IconButtonItem
                  data-for={`showToolTipPrint_${item.id}`}
                  data-tip
                >
                  <SvgIcon
                    icon="faxIcon"
                    width={16}
                    height={16}
                    color="fill/dark"
                  />
                  <Font fontType="body2" color="fill/dark" noWhiteSpace>
                    프린트
                  </Font>
                </Style.IconButtonItem>
              </>
            }
            onClick={async (event: MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              event.preventDefault();
              // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
              const prescriptionData = await fetchPrescriptionData(item.id);
              printJS({
                printable: prescriptionData[0].image,
                type: 'pdf',
                showModal: true,
              });
            }}
          />
        ) : null}

        <Button
          padding="5px 12px 5px 9px"
          backgroundColor={COLOR['fill/accent']}
          title={
            <Style.IconButtonItem
              data-for={`showToolTipFinishTelepharmacy_${item.id}`}
              data-tip
            >
              <SvgIcon icon="checkCircleIcon" width={16} color="fill/white" />
              <Font fontType="body2" color="fill/white" noWhiteSpace>
                조제완료
              </Font>
            </Style.IconButtonItem>
          }
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
            if (deliveryType === '방문수령') {
              finishTelepharmacy({ isPickup: true, id: item.id });
              return;
            }
            dispatch(
              openModal_finishTelepharmacyModal({
                item: {
                  id: item.id,
                  patientName: item.patient?.name,
                  patientAddress: item.address_string,
                  addressMemo: item.address_memo,
                  method: item.method,
                  patientPhone: item.patient?.phone || item.requester?.phone,
                  pharmacy_product_payment: item.pharmacy_product_payment,
                  address_entrance_password:
                    item.address_entrance_password || NO_PASSWORD_CASE_TEXT,
                  pharmacy_request_detail: item.pharmacy_request_detail,
                },
              }),
            );
          }}
        />
      </Style.ListButtonItem>
    </Style.ListItem>
  );
}

export default ListItem;
