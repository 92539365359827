import { Font } from '@components/atoms';
import * as Style from './index.style';

interface TabProps {
  key: number;
  title: string;
  onClick: () => void;
}

interface HeaderTabProps {
  tabs: TabProps[];
  selectedTabIndex: number;
}

function HeaderTab({ tabs, selectedTabIndex }: HeaderTabProps) {
  return (
    <Style.HeaderTabArea>
      {tabs.map((tab, idx) => {
        const { key, title, onClick } = tab;
        const isSelected = selectedTabIndex === idx;

        return (
          <Style.HeaderTab key={key} isSelected={isSelected} onClick={onClick}>
            <Font
              fontType="body2_medium"
              color={isSelected ? 'fill/white' : 'fill/dark'}
            >
              {title}
            </Font>
          </Style.HeaderTab>
        );
      })}
    </Style.HeaderTabArea>
  );
}

export default HeaderTab;
