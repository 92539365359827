import { tokenList } from '@constants';
import { storageHandler } from '@utils';
import { setSessionStorageState } from '@utils/storageHandler';

const { REST_AUTH_TOKEN } = tokenList;

export const logout = async () => {
  const { removeLocalStorageState } = storageHandler;
  removeLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN);
  removeLocalStorageState(REST_AUTH_TOKEN.REFRESH_TOKEN);
  removeLocalStorageState(REST_AUTH_TOKEN.MY_DOCTOR_TOKEN);
  setSessionStorageState('email', '');
};
