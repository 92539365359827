import { commonHooks } from '@hooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_signupModal } from '@stores/modalStore/signupModal';
import {
  firstLogin,
  selectUserInformationIsLogin,
} from '@stores/userInformationStore';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useLoginPageHook = () => {
  const navigate = useNavigate();
  const { useQuery, useMount } = commonHooks;
  const query = useQuery();

  const isLogin = useAppSelector(selectUserInformationIsLogin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogin) {
      navigate('/home', { replace: true });
    }
  });

  useMount(() => {
    const queryEmail = query.get('id');
    const queryPassword = query.get('password');

    if (queryEmail && queryPassword) {
      dispatch(firstLogin({ email: queryEmail, password: queryPassword }));
    }
  });

  const openSignupModal = useCallback(() => {
    dispatch(openModal_signupModal());
  }, [dispatch]);

  return {
    openSignupModal,
  };
};

export default useLoginPageHook;
