import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${COLOR['fill/white']};
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleContentNoticeArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const TitleButtonsArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ModalBodyArea = styled.div`
  display: flex;
  gap: 20px;
`;

export const ModalLeftBodyArea = styled.div<{
  medicineCompoundingFeesLength: number | undefined;
}>`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 520px;
  height: fit-content;
  max-height: 740px;
  padding: ${({ medicineCompoundingFeesLength }) =>
    medicineCompoundingFeesLength && medicineCompoundingFeesLength >= 6
      ? '60px 30px 30px 30px'
      : '30px'};
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 16px;
`;

export const ModalLeftBodyTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ModalRightBodyArea = styled.div`
  overflow-y: auto;
  width: 360px;
  max-height: 740px;
  padding: 30px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 16px;
`;

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MedicineSearchDisabledInputArea = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  border: 1px solid ${COLOR['border/outline']};
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
  cursor: disabled;
`;

export const MedicinePriceEachUnitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UnitDropDownArea = styled.div`
  position: relative;
  border-radius: 8px;
`;

export const MedicineUnitInputArea = styled.div<{
  isDisabled: boolean | undefined;
}>`
  display: flex;
  justify-content: space-between;
  width: 100px;
  padding: 14px 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  cursor: ${(props) => (props.isDisabled ? 'disabled' : 'pointer')};
  background-color: ${(props) =>
    props.isDisabled ? COLOR['disabled/background'] : COLOR['fill/white']};
`;

export const UnitArea = styled.div`
  padding: 14px 16px;
  cursor: pointer;
  :hover {
    background-color: ${COLOR.blue50};
  }
`;

export const MedicineSumInputArea = styled.div<{
  isFocus: boolean;
  isDisabled: boolean | undefined;
}>`
  flex: 4;
  display: flex;
  width: 302px;
  gap: 8px;
  padding: 12px 16px;
  border: ${(props) =>
    `1px solid ${
      props.isFocus ? COLOR['fill/black'] : COLOR['border/outline']
    }`};
  border-radius: 8px;
  background-color: ${(props) =>
    props.isDisabled ? COLOR['disabled/background'] : COLOR['fill/white']};
  cursor: ${(props) => (props.isDisabled ? 'disabled' : 'pointer')};
`;

export const MedicineSearchDisabledInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  background-color: ${COLOR['fill/light']};
`;

export const MedicineSearchInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
  :disabled {
    background-color: ${COLOR['fill/light']};
    color: ${COLOR['disabled/foreground']};
  }
`;

export const MedicineSelectedButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;

export const UnitAddButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;

export const RequiredUI = styled.div`
  padding: 2px 8px;

  border-radius: 4px;
  background-color: ${COLOR['label/lightBlue']};
`;

export const ModalFooterArea = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${COLOR['border/divider']};
  display: flex;
  gap: 12px;
`;
