import {
  coldChainImage,
  lightImage,
  parcelImage,
  pickupImage,
  quickImage,
  ReactBootstrapIcons,
} from '@assets';
import {
  Blank,
  Button,
  CheckBox,
  CheckButton,
  COLOR,
  Font,
  TextArea,
  TextInput,
} from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
  PageLoader,
} from '@components/molecules';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import { useAppDispatch } from '@stores/hooks';
import { openModal_recheckRequestModal } from '@stores/modalStore/recheckRequestModal';
import ReactTooltip from 'react-tooltip';
import {
  FinishTelepharmacyModalTemplateType,
  PaymentInformationDataType,
} from './index.d';
import * as Style from './index.style';
import { NudgeComponent } from './sections';

const { COMMON_CODE } = commonCode;

const modalCustomStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '580px',
    backgroundColor: COLOR['fill/white'],
    maxHeight: '80vh',
    borderRadius: '16px',
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: COLOR['dim/light'],
    zIndex: 100,
  },
};

function FinishTelepharmacyModalTemplate({
  isPageLoading,
  isVisible,
  item,
  closeModal,
  finishTelepharmacy,
  internal,
  setInternal,
  external,
  setExternal,
  injection,
  setInjection,
  medicine_day_num,
  onChangeMedicine_day_num,
  isMedicine_day_num_not_decided_yet,
  setIsMedicine_day_num_not_decided_yet,
  morning,
  setMorning,
  lunch,
  setLunch,
  dinner,
  setDinner,
  beforeSleep,
  setBeforeSleep,
  medicine_period_num,
  whenever_patient_want,
  setWhenever_patient_want,
  is_directly_input,
  setIs_Directly_input,
  is_supplement_info_exposed,
  setIs_supplement_info_exposed,
  before_meal,
  setBefore_meal,
  after_meal,
  setAfter_meal,
  supplement_info,
  setSupplement_info,
  onChangeSupplement_info,
  buttonVisible,
  getMedicineGuideData,
}: FinishTelepharmacyModalTemplateType) {
  const { phoneFormatter, priceFormatter } = formatters;
  const dispatch = useAppDispatch();

  let deliveryType: string;
  let iconURL;
  let deliveryTopText;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      deliveryTopText = '택배기사님은 다음 날 방문 예정입니다.';
      // deliveryBottomText =
      //   '나만의닥터 전용 박스에 포장 후 조제완료를 진행해주세요.';
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      deliveryTopText = '';
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      deliveryTopText = '배달기사님은 30~60분 이후 방문 예정입니다.';
      // deliveryBottomText =
      //   '조제완료 후 나만의닥터 전용 쇼핑백 또는 박스에 포장을 진행해주세요.';
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      deliveryTopText =
        '기사님께서 오후 12~3시에 약국 방문 예정입니다. 오후 1시 이후 결제 건은 다음날에 수거됩니다.';
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      deliveryTopText = '';
      break;
  }

  const pharmacyProductDataList =
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.items &&
    item.pharmacy_product_payment.items.length > 0
      ? item.pharmacy_product_payment.items
      : [];

  const paymentInformationList: PaymentInformationDataType[] = [
    {
      id: 0,
      title: '추가 구매상품',
      dataList: pharmacyProductDataList.map((data) => ({
        description: data.pharmacy_product_name
          ? `${data.pharmacy_product_name}${
              data.pharmacy_product_capacity
                ? `, ${data.pharmacy_product_capacity}`
                : ''
            }`
          : '-',
        amount: data.amount || 0,
        count: data.quantity || 0,
      })),
    },
  ];

  // TODO: 약국 요청사항
  // const { telePharmacyItem } = useTelePharmacyItem(item?.id);
  console.log(deliveryTopText);

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isOpen={isVisible}
        modalStyle={modalCustomStyle}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="h1">
              {item.patientName} 환자 (
              {phoneFormatter.lastFourPhoneNumberFormatter(item.patientPhone)})
              {iconURL ? undefined : ' ❄️'}
            </Font>
            {iconURL ? (
              <Style.TitleIcon src={iconURL} alt={deliveryType} />
            ) : undefined}
          </Style.TitleArea>
        }
        modalBody={
          <>
            {deliveryTopText && (
              <Style.DescriptionArea>
                <CallOutMessage>
                  <Font fontType="body1">{deliveryTopText}</Font>
                </CallOutMessage>
              </Style.DescriptionArea>
            )}

            {/* TODO: 약국 요청사항 */}
            {/* {telePharmacyItem?.pharmacy_request_detail ? (
              <BFlex isColumn gap={12} padding="0 0 1.25rem 0">
                <Font fontType="h2" color={COLOR["fill/dark"]}>
                  약국 요청사항
                </Font>

                <Font fontType="h4" color={COLOR["fill/dark"]}>
                  {telePharmacyItem?.pharmacy_request_detail}
                </Font>
              </BFlex>
            ) : undefined} */}
            {pharmacyProductDataList.length > 0 ? (
              <>
                <Blank appoint="Vertical" size={20} />
                <Style.DescriptionArea>
                  <Style.DescriptionTitleArea>
                    <Font fontType="h2" color="fill/dark">
                      추가 구매 상품
                    </Font>
                  </Style.DescriptionTitleArea>
                  <Style.DescriptionTitleArea>
                    <Font fontType="body2" color="fill/medium">
                      *아래 상품을 처방약과 함께 나만의닥터 전용 쇼핑백 또는
                      박스에 포장을 진행해주세요.
                    </Font>
                  </Style.DescriptionTitleArea>
                  <Blank appoint="Vertical" size={3} />
                  <Style.DescriptionDetailArea>
                    {paymentInformationList.map((paymentInformationItem) => {
                      if (paymentInformationItem.dataList.length > 0) {
                        return (
                          <Style.ContentBodyContainer
                            key={paymentInformationItem.id}
                          >
                            <Style.ContentBodyTextArea>
                              {paymentInformationItem.dataList.map(
                                (data, index) => (
                                  <Style.ContentBodyText key={index}>
                                    <Style.ContentBodyTextLeft>
                                      <Font fontType="h4" color="fill/black">
                                        {data.description}
                                      </Font>
                                    </Style.ContentBodyTextLeft>
                                    <Style.ContentBodyTextRight>
                                      <Style.ContentBodyTextRightTop>
                                        <Font fontType="h4" color="fill/black">
                                          {priceFormatter.commaFormatter(
                                            data.amount.toString(),
                                          )}
                                          원
                                        </Font>
                                      </Style.ContentBodyTextRightTop>
                                      <Style.ContentBodyTextRightBottom>
                                        <Font fontType="h4" color="fill/accent">
                                          {data.count > 0
                                            ? `수량 ${data.count}개`
                                            : ''}
                                        </Font>
                                      </Style.ContentBodyTextRightBottom>
                                    </Style.ContentBodyTextRight>
                                  </Style.ContentBodyText>
                                ),
                              )}
                            </Style.ContentBodyTextArea>
                          </Style.ContentBodyContainer>
                        );
                      }
                      return null;
                    })}
                  </Style.DescriptionDetailArea>
                </Style.DescriptionArea>
                <Style.HorizontalLine />
              </>
            ) : null}
            <Blank appoint="Vertical" size={20} />
            <Style.DescriptionArea>
              <Style.DescriptionTitleArea>
                <Font fontType="h2" color="fill/dark">
                  복약 안내
                </Font>
              </Style.DescriptionTitleArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4" color="fill/dark">
                    약제 종류
                  </Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setInternal(!internal)}
                      isSelected={internal}
                      title="내용제"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setExternal(!external)}
                      isSelected={external}
                      title="외용제"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setInjection(!injection)}
                      isSelected={injection}
                      title="주사제"
                    />
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4" color="fill/dark">
                    투약 일수
                  </Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.InputArea>
                    <Font fontType="body1" color="fill/dark">
                      총
                    </Font>
                    <Blank appoint="Horizontal" size={8} />
                    <TextInput
                      value={medicine_day_num}
                      type="text"
                      onChange={onChangeMedicine_day_num}
                      fontSize={16}
                      placeholder="숫자만 입력해주세요."
                      width={160}
                      height={38}
                      disabled={
                        (external && !internal && !injection) ||
                        isMedicine_day_num_not_decided_yet
                      }
                    />
                    <Blank appoint="Horizontal" size={8} />
                    <Font fontType="body1">일</Font>
                  </Style.InputArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() =>
                        setIsMedicine_day_num_not_decided_yet(
                          !isMedicine_day_num_not_decided_yet,
                        )
                      }
                      isSelected={isMedicine_day_num_not_decided_yet}
                      title="투약 일수 미정입니다."
                    />
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Style.DescriptionDetailMiddleTextArea>
                    <Font fontType="h4">투약 횟수</Font>
                  </Style.DescriptionDetailMiddleTextArea>
                  <Style.DescriptionDetailMiddleTextArea>
                    <Font fontType="body2">(1일 {medicine_period_num}회)</Font>
                  </Style.DescriptionDetailMiddleTextArea>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setMorning(!morning)}
                      isSelected={morning}
                      title="아침"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setLunch(!lunch)}
                      isSelected={lunch}
                      title="점심"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setDinner(!dinner)}
                      isSelected={dinner}
                      title="저녁"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setBeforeSleep(!beforeSleep)}
                      isSelected={beforeSleep}
                      title="취침 전"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() =>
                        setWhenever_patient_want(!whenever_patient_want)
                      }
                      isSelected={whenever_patient_want}
                      title="필요 시"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() =>
                        setIs_supplement_info_exposed(
                          !is_supplement_info_exposed,
                        )
                      }
                      isSelected={is_supplement_info_exposed}
                      title="추가 안내 참고"
                    />
                    <Style.CheckBoxIconArea>
                      <ReactBootstrapIcons.QuestionCircle
                        size={14}
                        color={COLOR['fill/dark']}
                        data-for="showToolTipGuide_1"
                        data-tip
                      />
                      <ReactTooltip
                        id="showToolTipGuide_1"
                        place="left"
                        isCapture
                      >
                        <Style.TooltipMessageArea>
                          <Blank appoint="Vertical" size={4} />
                          <Style.TooltipMessage>
                            <Style.TooltipImage src={lightImage} alt="light" />
                            추가 안내 참고
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={12} />
                          <Style.TooltipMessage>
                            처방된 약제마다 투약 횟수가 상이한 경우,
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={4} />
                          <Style.TooltipMessage>
                            이 항목을 체크하고 추가 안내에 상세한 복약
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={4} />
                          <Style.TooltipMessage>
                            안내를 기입해주세요.
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={4} />
                        </Style.TooltipMessageArea>
                      </ReactTooltip>
                    </Style.CheckBoxIconArea>
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required={false} />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4">투약 시점</Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setAfter_meal(!after_meal)}
                      isSelected={after_meal}
                      title="식사 후"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setBefore_meal(!before_meal)}
                      isSelected={before_meal}
                      title="식사 전"
                    />
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required={false} />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4">추가 안내</Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckBox
                      onClick={() => {
                        setIs_Directly_input(false);
                        setSupplement_info('');
                      }}
                      isSelected={is_directly_input === false}
                      title="약 봉투 참고"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckBox
                      onClick={() => setIs_Directly_input(true)}
                      isSelected={is_directly_input === true}
                      title="직접 입력"
                    />
                  </Style.CheckBoxArea>
                  {is_directly_input && (
                    <Style.MultiLineInputArea>
                      <TextArea
                        value={supplement_info}
                        type="text"
                        onChange={onChangeSupplement_info}
                        fontSize={16}
                        placeholder="환자에게 추가로 안내할 주의사항을 적어주세요."
                        width={307}
                        height={120}
                      />
                      <Blank appoint="Vertical" size={8} />
                      <Font fontType="body2">{supplement_info.length}/500</Font>
                    </Style.MultiLineInputArea>
                  )}
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
            </Style.DescriptionArea>
          </>
        }
        modalFooter={
          <Button
            onClick={() => {
              return deliveryType === '택배' ||
                deliveryType === '냉장' ||
                deliveryType === '퀵'
                ? dispatch(
                    openModal_recheckRequestModal({
                      item: {
                        ...item,
                        guideData: getMedicineGuideData(),
                      },
                    }),
                  )
                : finishTelepharmacy({ isPickup: true });
            }}
            disableColor={COLOR['fill/medium']}
            backgroundColor={COLOR['fill/accent']}
            visible={buttonVisible}
            width="136px"
            padding="16px 12px"
            title={
              <Style.ButtonContainer>
                {iconURL ? (
                  <Style.ButtonIcon src={iconURL} alt={deliveryType} />
                ) : undefined}
                <Font fontType="body2" color="fill/white">
                  {deliveryType === '방문' ? '방문 요청하기' : '수거 요청하기'}
                </Font>
              </Style.ButtonContainer>
            }
          />
        }
      />
    </>
  );
}

export default FinishTelepharmacyModalTemplate;
