import styled from 'styled-components';

export const NotificationToastArea = styled.div`
  display: flex;
  height: 175px;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  padding: 20px;
`;

export const NotificationTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const NotificationContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const NotificationMethodButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const ChevronDownIconArea = styled.div`
  rotate: 270deg;
`;

export const CloseButtonArea = styled.div`
  cursor: pointer;
`;
