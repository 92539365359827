import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TitleArea = styled.div`
  margin-top: 15px;
`;

export const PatientNameArea = styled.div``;

export const PatientPaymentArea = styled.div`
  display: flex;

  flex-direction: column;
  gap: 12px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const InformationLine = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const InformationTitle = styled.li`
  width: 150px;
  color: ${COLOR['fill/dark']};
`;

export const CheckBoxWidth = styled.div`
  width: 150px;
`;

export const ImageItem = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;

export const RequiredUI = styled.div`
  padding: 2px 8px;

  border-radius: 4px;
  background-color: ${COLOR['label/lightBlue']};
`;

export const DeleteImageIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 13px;
`;

export const InformationColumnArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const AmountInputArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CheckBoxButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const CheckboxIconArea = styled.div`
  cursor: pointer;
`;

export const CheckButtonMargin = styled.div`
  margin-top: 12px;
`;

export const DescriptionArea = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

export const IsSubstituteSelectArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const IsSubstituteSelectButtonsArea = styled.div`
  display: flex;
  gap: 8px;
`;

export const SubstituteReasonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

export const SubstituteReasonRadiosArea = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubstituteReasonRadioArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
`;

export const SubstituteReasonRadio = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const SubstituteReasonTextareaArea = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 12px 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  resize: none;
  color: ${COLOR['fill/black']};
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
`;

export const SubstituteReasonLabel = styled.label`
  cursor: pointer;
`;

export const FooterArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ConfirmButtonArea = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
