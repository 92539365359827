import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { notificationOval } from '@components/atoms/lottie';

import {
  getBrowserInfo,
  getNotificationPermissionStatus,
} from '@utils/browser';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import * as Style from './index.style';

function NotificationToast() {
  const userBrowserInfo = getBrowserInfo();
  const permissionStatus = getNotificationPermissionStatus();

  const isEdgeNotificationAllow =
    userBrowserInfo === 'Edge' &&
    permissionStatus === '이 브라우저는 알림을 지원하지 않습니다.';

  const isChormeNotificationAllow =
    userBrowserInfo === 'Chrome' &&
    permissionStatus === '이 브라우저는 알림을 지원하지 않습니다.';

  const handleClickNotificationMethod = () => {
    if (!window) return;

    if (userBrowserInfo === 'Chrome') {
      window.open(
        'https://merakiplace.notion.site/Chrome-17d7f40596d2447e8f557e56800863df?pvs=25',
        '_blank',
      );
    } else if (userBrowserInfo === 'Edge') {
      window.open(
        'https://merakiplace.notion.site/Edge-7ebedfa4378b41988602e3e8a2f710cb',
        '_blank',
      );
    } else {
      window.open(
        'https://merakiplace.notion.site/Edge-7ebedfa4378b41988602e3e8a2f710cb',
        '_blank',
      );
    }
  };
  // https://merakiplace.notion.site/Chrome-a3b1e006c41246dba79eb5bb57fd8b68?pvs=25
  // https://merakiplace.notion.site/Edge-58cf498831fd419f9743dd591ed4680d?pvs=4
  return (
    <Style.NotificationToastArea>
      <div>
        <Lottie animationData={notificationOval} style={{ width: 32 }} />
      </div>
      <Style.NotificationContentArea>
        <Style.NotificationTextArea>
          <Font fontType="h3" color="fill/black">
            조제 접수 알림을 켜주세요!
          </Font>
          <Font fontType="body2" color="fill/dark">
            {isChormeNotificationAllow && (
              <>
                크롬 브라우저의 알림 설정이 꺼져 있어요.
                <br />
                알림을 켜고 조제요청을 더 쉽게 확인해 보세요!
              </>
            )}
            {isEdgeNotificationAllow && (
              <>
                엣지 브라우저의 알림 설정이 꺼져 있어요.
                <br />
                알림을 켜고 조제요청을 더 쉽게 확인해 보세요!
              </>
            )}
            {!isEdgeNotificationAllow && !isChormeNotificationAllow && (
              <>
                현재 조제 접수 알림이 비활성화된 상태에요.
                <br /> 알림을 켜면 새로운 조제 요청을 바로 알려드려요!
              </>
            )}
          </Font>
        </Style.NotificationTextArea>

        <Button
          width="136px"
          padding="5px 9px 5px 12px"
          backgroundColor={COLOR['fill/accent']}
          borderRadius="6px"
          onClick={() => {
            handleClickNotificationMethod();
          }}
          title={
            <Style.NotificationMethodButtonArea>
              <Font fontType="body2_medium" color="fill/white">
                알림 설정법 보기
              </Font>
              <Style.ChevronDownIconArea>
                <SvgIcon icon="chevronDown" width={16} color="fill/white" />
              </Style.ChevronDownIconArea>
            </Style.NotificationMethodButtonArea>
          }
        />
      </Style.NotificationContentArea>
      <Style.CloseButtonArea
        onClick={() => {
          toast.dismiss();
        }}
      >
        <SvgIcon icon="closeIcon" width={20} color="fill/medium" />
      </Style.CloseButtonArea>
    </Style.NotificationToastArea>
  );
}

export default NotificationToast;
