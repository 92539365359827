import { getPrescriptionDetail } from '@mobile/api';
import AuthError from '@mobile/pages/AuthError';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

export type TPDetailLocationState = {
  state: { prescriptionId?: string };
};

const usePrescriptionDetail = () => {
  // usePrescriptionDetail을 Accept에도 써야하는 상황이 일어남.
  // 타입은 TPDetailLocationState지만 TPAcceptLocationState에도 presciriptID가 있기에 문제는 안됨.
  const { state } = useLocation() as TPDetailLocationState;

  const navigate = useNavigate();

  return useQuery({
    queryFn: () =>
      getPrescriptionDetail({
        prescriptionId: String(state?.prescriptionId || 0),
      }),
    queryKey: [`TP_PRESCRIPTION_${state?.prescriptionId || 0}`],
    retry: false,
    onError: (error) => {
      if (error instanceof AuthError) {
        return navigate('/mobile/auth-error');
      }

      alert('처방전 데이터를 불러오는데 실패했습니다.');

      return navigate('/mobile/request');
    },
  });
};

export default usePrescriptionDetail;
