import { COLOR } from '@components/atoms';
import { SPACING_VARIABLES, Z_INDEX } from '@constants/styleVariables';
import styled from 'styled-components';

export const HeaderTabArea = styled.div`
  position: fixed;
  top: ${SPACING_VARIABLES.headerHeight};
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100vw;
  height: ${SPACING_VARIABLES.headerTabHeight};
  padding: 12px 160px;
  background-color: ${COLOR['fill/white']};
  border-top: 1px solid ${COLOR['border/divider']};
  z-index: ${Z_INDEX.headerTab};
`;

export const HeaderTab = styled.div<{ isSelected: boolean }>`
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? COLOR['fill/dark'] : COLOR['border/outline']};
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR['fill/dark'] : COLOR['fill/white']};
  cursor: pointer;
`;
