import { Button, COLOR, Font } from '@components/atoms';
import BModal from '@components/meraki-ui/BModal';
import useMedicineManagementModalHook from '@pages/wrapper/modals/medicineManagementModal/hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getPharmacyAutoConfirmed,
  selectMedicineManagementList,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import usePharmacyAutoConfirmedModalHook from './hooks';
import * as Style from './index.style';

function PharmacyAutoConfirmedModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const managementMedicineList = useAppSelector(selectMedicineManagementList);
  const { isVisible, modalType, closeModal } =
    usePharmacyAutoConfirmedModalHook();
  const { openModal } = useMedicineManagementModalHook();
  const dispatch = useAppDispatch();

  const handleClickExecute = async () => {
    if (!pharmacist.pharmacy_id) return;

    if (modalType === 'on') {
      await myDoctorAPI
        .patchPharmacyAutoConfirmed({
          id: pharmacist.pharmacy_id || 0,
          auto_confirmed: true,
        })
        .then(() => {
          dispatch(
            getPharmacyAutoConfirmed({
              id: pharmacist.pharmacy_id || 0,
            }),
          );
        })
        .then(() => {
          closeModal();
          if (managementMedicineList?.length === 0) {
            openModal();
          }
        });
    }
    if (modalType === 'off') {
      await myDoctorAPI
        .patchPharmacyAutoConfirmed({
          id: pharmacist.pharmacy_id || 0,
          auto_confirmed: false,
        })
        .then(() => {
          dispatch(
            getPharmacyAutoConfirmed({
              id: pharmacist.pharmacy_id || 0,
            }),
          );
        })
        .then(() => {
          closeModal();
        });
    }
  };

  return (
    <BModal
      open={isVisible}
      width={480}
      padding={30}
      onOpenChange={() => closeModal()}
    >
      <Style.ModalArea>
        <Style.ModalBodyArea>
          <Font fontType="h1" color="fill/black">
            {modalType === 'on' && '조제 자동수락을 활성화하시겠어요?'}
            {modalType === 'off' && '조제 자동수락을 비활성화하시겠어요?'}
          </Font>
          <Font fontType="body1_medium" color="fill/dark">
            {modalType === 'on' &&
              `등록된 의약품의 처방전 조제 접수가 자동으로 수락돼요.`}
            {modalType === 'off' &&
              `등록된 의약품의 처방전 조제 접수가 더이상 자동 수락되지 않아요.`}
          </Font>
        </Style.ModalBodyArea>
        <Style.ModalFooterArea>
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body1" color="fill/dark">
                아니오
              </Font>
            }
            onClick={closeModal}
          />
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/accent']}
            title={
              <Font fontType="body1" color="fill/white">
                네
              </Font>
            }
            onClick={handleClickExecute}
          />
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default PharmacyAutoConfirmedModal;
