import { Mixpanel } from '@utils/mixpanel';

const waitForDispenseMixpanelEvents = {
  pageTPConfirmedList: '[Page] TP Confirmed List',
  pageTPConfirmedDetail: '[Page] TP Confirmed Detail',
} as const;

interface WaitForDispenseMixpanelEventsMapProps {
  pageTPConfirmedList: ({
    listCount,
    pharmacyId,
  }: {
    listCount: number;
    pharmacyId: string;
  }) => void;
  pageTPConfirmedDetail: ({
    tppId,
    phId,
    tmId,
    deliveryMethod,
    symptomKeyword,
    tmGuideType,
  }: {
    tppId: string;
    phId: string;
    tmId: string;
    deliveryMethod: string;
    symptomKeyword: string;
    tmGuideType: string;
  }) => void;
}

export const trackWaitForDispense: WaitForDispenseMixpanelEventsMapProps = {
  pageTPConfirmedList: ({ listCount, pharmacyId }) =>
    Mixpanel.track(waitForDispenseMixpanelEvents.pageTPConfirmedList, {
      'Number of List': listCount,
      'PH ID': pharmacyId,
    }),
  pageTPConfirmedDetail: ({
    tppId,
    phId,
    tmId,
    deliveryMethod,
    symptomKeyword,
    tmGuideType,
  }) =>
    Mixpanel.track(waitForDispenseMixpanelEvents.pageTPConfirmedDetail, {
      'TPP ID': tppId,
      'PH ID': phId,
      'TM ID': tmId,
      'Delivery Method':
        deliveryMethod === '방문' ? '방문수령' : deliveryMethod,
      'Symptom Keyword': symptomKeyword,
      'TM Guide Type': tmGuideType,
    }),
};
