import { socketServerURL } from '@constants';
import { REST_AUTH_TOKEN } from '@constants/tokenList';
import * as Sentry from '@sentry/react';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import {
  getTelepharmacyList_accepted,
  getTelepharmacyList_confirmed,
  getTelepharmacyList_finished_parcel,
  getTelepharmacyList_finished_pickup,
  getTelepharmacyList_finished_quick,
  getTelepharmacyList_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationUserData } from '@stores/userInformationStore';
import { getLocalStorageState } from '@utils/storageHandler';
import { io, Socket } from 'socket.io-client';

type AllowedTopicType = 'telepharmacy' | 'telepharmacy_payment' | 'pharmacist';
const emitEvent_notifyOtherClients = 'notify_other_clients';
const emitEvent_sendAck = 'client_ack';

let socketInstance: Socket | null = null;

const TP_REQUEST = '[TP]약사_조제요청';
const useSocket = () => {
  const sound = new Audio(
    'https://d7qkbi83dtokl.cloudfront.net/prod/web/common/bell_ring.mp3',
  );
  const socketServerUrl = socketServerURL.SocketServerConstant();
  const userData = useAppSelector(selectUserInformationUserData);
  const dispatch = useAppDispatch();

  if (!socketInstance && userData && userData.user_id !== 0) {
    socketInstance = io(socketServerUrl, {
      transports: ['websocket'],
      reconnectionAttempts: 5,
      reconnectionDelay: 2500,
      reconnectionDelayMax: 5000,
    });
    socketInstance.on('connect', () => {
      // 연결 후 ACCESS_TOKEN은 항상 최신 값을 사용 해줍니다.
      const ACCESS_TOKEN = getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN);
      console.warn(`socket connected ${userData.user_id}`);
      // 소켓에 연결되면 유저 Credential을 전송하고 방에 입장시켜 달라고 졸라봅니다.
      socketInstance?.emit('let_me_in', {
        UserId: userData.user_id,
        AccessToken: ACCESS_TOKEN,
      });
    });
    socketInstance.on('events', async (msg) => {});

    socketInstance.on('connect_error', (err) => {
      // track using sentry
      Sentry.captureException(err);
    });

    socketInstance.on('disconnect', (reason) => {
      console.warn(`disconnect: ${reason}`);
      // reconnect after 60 sec
      setTimeout(() => {
        socketInstance?.connect();
      }, 60000);
    });

    socketInstance.on('telepharmacy', async (msg) => {
      if (msg.alarm_name === TP_REQUEST) {
        try {
          await sound.play();
        } catch (e) {
          console.error(e);
        }
      }

      sendAck('telepharmacy');
      dispatch(getTelepharmacyCount());
      dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 }));
      dispatch(getTelepharmacyList_accepted({ limit: 60, offset: 0 }));
      dispatch(getTelepharmacyList_finished_parcel({ limit: 60, offset: 0 }));
      dispatch(getTelepharmacyList_finished_pickup({ limit: 60, offset: 0 }));
      dispatch(getTelepharmacyList_finished_quick({ limit: 60, offset: 0 }));
      dispatch(getTelepharmacyList_confirmed({ limit: 60, offset: 0 }));
    });

    socketInstance.on('telepharmacy_payment', async (msg) => {
      sendAck('telepharmacy_payment');
      dispatch(getTelepharmacyCount());
      dispatch(getTelepharmacyList_accepted({ limit: 60, offset: 0 }));
      dispatch(getTelepharmacyList_confirmed({ limit: 60, offset: 0 }));
    });

    socketInstance.on('reload_self', async (msg) => {
      window.location.reload();
    });
  }

  return { socket: socketInstance, socketConencted };
};

/**
 * @description 같은 context에 있는 유저들에게 업데이트가 발생했음을 알려줍니다. (수락 : 접수대기->결제대기 리스트 업데이트 등)
 * @param topic 'telepharmacy' | 'telepharmacy_payment' | 'pharmacist'
 */
export const notifyOtherClients = (topic: AllowedTopicType) => {
  if (socketInstance) {
    socketInstance.emit(emitEvent_notifyOtherClients, {
      topic,
    });
  }
};

/**
 * @description 소켓 서버에 메시지를 수신했음을 알려줍니다. (로깅/모니터링용)
 * @param topic 'telepharmacy' | 'telepharmacy_payment' | 'pharmacist'
 */
export const sendAck = (topic: AllowedTopicType) => {
  if (socketInstance) {
    socketInstance.emit(emitEvent_sendAck, { topic });
  }
};

/**
 * 로그아웃시 소켓을 닫고 인스턴스를 초기화합니다. (다시 로그인시 소켓을 다시 연결합니다.)
 */
export const disconnectSocketAndReset = () => {
  if (socketInstance) {
    socketInstance.disconnect();
    socketInstance = null;
  }
};

export const socketConencted = () => {
  return socketInstance?.connected || false;
};

export default useSocket;
