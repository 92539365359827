import { Blank, Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BTooltip from '@components/meraki-ui/BTooltip';
import useTooltip from '@components/meraki-ui/BTooltip/useTooltip';
import ReservationInformationItem from '@components/organisms/telepharmacyDetailInformation/sections/reservationInformationPartItem/ReservationInformationItem';
import { getReservationInformationList } from '@components/organisms/utils/domain';
import { handleCopyClipBoard } from '@utils/clipboard';
import { useCallback } from 'react';
import { ReservationInformationPartItemType } from './index.d';
import * as Style from './index.style';

function ReservationInformationPartItem({
  item,
  iconURL,
  status,
}: ReservationInformationPartItemType) {
  const { tooltipVisible, openTooltip } = useTooltip();

  const onClick = useCallback(async () => {
    let copyText = '';
    getReservationInformationList({ item, status }).forEach((itemInfo) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      itemInfo.canCopy && (copyText += `${itemInfo.text}\n`);
    });

    try {
      await handleCopyClipBoard(copyText ?? '');

      openTooltip();
    } catch (error) {
      console.error(error);
    }
  }, [item, openTooltip, status]);

  return (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            예약정보
          </Font>
        </Style.ContentHeaderTitle>
        <Button
          width="100px"
          height="40px"
          title={
            <Style.DuplicateButtonArea>
              <SvgIcon
                icon="duplicateIcon"
                width={16}
                height={16}
                color="fill/accent"
              />

              <Font fontType="body2_medium" color="fill/accent">
                전체 복사
              </Font>
            </Style.DuplicateButtonArea>
          }
          borderColor="none"
          backgroundColor={COLOR['label/lightBlue']}
          borderRadius="8px"
          onClick={onClick}
        />
        {tooltipVisible ? (
          <BTooltip
            visible={tooltipVisible}
            side="right"
            margin="-2rem 0 0 30rem"
            title="복사되었습니다."
            marginNumber={0}
          />
        ) : undefined}
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentBodyArea>
        {getReservationInformationList({ item, status }).map(
          (reservationInformationItem) => {
            return (
              <ReservationInformationItem
                reservationInformationItem={reservationInformationItem}
                key={reservationInformationItem.id}
              />
            );
          },
        )}
      </Style.ContentBodyArea>
    </Style.RightContentItem>
  );
}

export default ReservationInformationPartItem;
