import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import useCommonModal from './hooks';
import * as Style from './index.style';

interface CommonModalProps {
  leftButtonCallback?: () => void;
  rightButtonCallback?: () => void;
}

function CommonModal({
  leftButtonCallback,
  rightButtonCallback,
}: CommonModalProps) {
  const { isVisible, closeModal } = useCommonModal({});

  return (
    <BModal open={isVisible} width={480} padding={30} onOpenChange={closeModal}>
      <Style.ModalArea>
        <Style.ModalTitleArea>
          <Font fontType="h1" color="fill/black">
            의약품 관리 기능이 바뀔 예정이에요
          </Font>
          <Style.CloseButtonArea onClick={closeModal}>
            <SvgIcon icon="closeIcon" width={24} color="fill/black" />
          </Style.CloseButtonArea>
        </Style.ModalTitleArea>

        <Style.ModalBodyArea>
          <Font fontType="body1_medium" color="fill/dark">
            9월 23일부터 약가와 조제료를 별도 설정 가능한 새로운 가격 체계가
            적용될 예정입니다.
          </Font>
          <Style.TextArea>
            <Style.DotIconArea>
              <SvgIcon icon="dotIcon" width={5} color="fill/black" />
            </Style.DotIconArea>
            <Font fontType="body1" color="fill/dark">
              기존에 등록하셨던 의약품들의 가격을 미리 재등록 부탁드립니다.
            </Font>
          </Style.TextArea>
          <Style.TextArea>
            <Style.DotIconArea>
              <SvgIcon icon="dotIcon" width={5} color="fill/black" />
            </Style.DotIconArea>
            <Font fontType="body1" color="fill/dark">
              새로운 가격 체계 적용 전까지는 기존 의약품의 가격으로 나만의닥터
              앱에 노출 및 자동수락됩니다.
            </Font>
          </Style.TextArea>
          <Style.TextArea>
            <Style.DotIconArea>
              <SvgIcon icon="dotIcon" width={5} color="fill/black" />
            </Style.DotIconArea>
            <Font fontType="body1" color="fill/dark">
              기존 의약품 가격과 자동수락 현황 확인이 필요하신 경우, [기존
              의약품 정보 보기] 버튼을 눌러 확인해주세요.
            </Font>
          </Style.TextArea>
        </Style.ModalBodyArea>

        <Style.Divider />

        <Style.ModalFooterArea>
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body1" color="fill/dark">
                기존 의약품 정보 보기
              </Font>
            }
            onClick={() => {
              if (leftButtonCallback) {
                leftButtonCallback();
              }
            }}
          />

          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/accent']}
            title={
              <Font fontType="body1" color="fill/white">
                새로운 의약품 가격 등록
              </Font>
            }
            onClick={() => {
              if (rightButtonCallback) {
                rightButtonCallback();
              }
            }}
          />
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default CommonModal;
