import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font, TextButton } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
  PageLoader,
  TextButtonWithTextArea,
} from '@components/molecules';
import { featureHooks } from '@hooks';
import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_rejectTelepharmacyModal,
  selectRejectTelepharmacyModalItem,
  selectRejectTelepharmacyModalVisible,
} from '@stores/modalStore/rejectTelepharmacyModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import { getTelepharmacyList_requested } from '@stores/telepharmacyStore/telepharmacyList';
import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Style from './index.style';

function RejectTelepharmacyModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { useFetchCancelReason } = featureHooks;
  const cancelReasonList = useFetchCancelReason({ type: 'TP' });
  const [selectedId, setSelectedId] = useState<number>();
  const isOpen = useAppSelector(selectRejectTelepharmacyModalVisible);
  const item = useAppSelector(selectRejectTelepharmacyModalItem);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [rejectReason, setRejectReason] = useState('');
  const [prescriptionProblemReason, setPrescriptionProblemReason] =
    useState('');
  const [extraReason, setExtraReason] = useState('');

  const selectReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setRejectReason(text);
    setPrescriptionProblemReason('');
    setExtraReason('');
  };

  const selectPrescriptionProblemReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setRejectReason('');
    setPrescriptionProblemReason(text);
    setExtraReason('');
  };

  const selectExtraReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setRejectReason('');
    setPrescriptionProblemReason('');
    setExtraReason(text);
  };

  const onChangePrescriptionProblemReason = (
    e: FormEvent<HTMLTextAreaElement>,
  ) => {
    setPrescriptionProblemReason(e.currentTarget.value);
  };

  const onChangeExtraReason = (e: FormEvent<HTMLTextAreaElement>) => {
    setExtraReason(e.currentTarget.value);
  };

  const closeModal = () => {
    setIsPageLoading(false);
    dispatch(closeModal_rejectTelepharmacyModal());
    setRejectReason('');
    setPrescriptionProblemReason('');
    setExtraReason('');
    setSelectedId(undefined);
  };

  const reject = () => {
    if (
      window.confirm(
        `${item.tm?.patient?.name} 환자의 조제를 거절하시겠습니까?`,
      )
    ) {
      setIsPageLoading(true);

      let reason;
      if (selectedId === 100) {
        reason = extraReason;
      } else if (selectedId === 101) {
        reason = `처방전에 문제가 있어 병원에 다시 확인이 필요합니다. ${prescriptionProblemReason}`;
      } else {
        reason = rejectReason;
      }
      const id = item.id ? item.id : 0;
      myDoctorAPI
        .postRejectTelepharmacy({ reject_reason: reason }, id)
        .then((responseJSON) => {
          if (responseJSON.id) {
            alert('조제를 거절했습니다.');
            closeModal();
            Promise.all([
              dispatch(getTelepharmacyCount()),
              dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 })),
            ]).then(() => {
              setTimeout(() => navigate('/home/newReservation/requested'), 700);
              notifyOtherClients('telepharmacy');
            });
          } else {
            alert('요청에 실패했습니다.');
            window.location.reload();
          }
        })
        .finally(() => setIsPageLoading(false));
    }
  };

  const buttonVisible =
    rejectReason.length >= 1 ||
    prescriptionProblemReason.length >= 1 ||
    extraReason.length >= 1;

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="display2" color="fill/dark">
              조제 거절
            </Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Style.DescriptionArea>
              <CallOutMessage>
                <Font fontType="body1" color="fill/dark">
                  환자가 거절 사유를 이해하고, 다음 예약 시에 진료 요청을 적절히
                  넣을 수 있도록 아래 문항을 선택해주세요.
                </Font>
              </CallOutMessage>
            </Style.DescriptionArea>
            {cancelReasonList.map((cancelReasonItem) => (
              <Style.TextButtonArea key={cancelReasonItem.id}>
                <TextButton
                  isSelected={selectedId === cancelReasonItem.id}
                  title={cancelReasonItem.text}
                  onClick={() =>
                    selectReject(cancelReasonItem.id, cancelReasonItem.text)
                  }
                  key={String(cancelReasonItem.id)}
                />
              </Style.TextButtonArea>
            ))}
            <Style.TextButtonArea>
              <TextButtonWithTextArea
                isSelected={selectedId === 101}
                title="처방전에 문제가 있어 병원에 다시 확인이 필요합니다."
                onClick={() =>
                  selectPrescriptionProblemReject(
                    101,
                    prescriptionProblemReason,
                  )
                }
                key={String(101)}
                value={prescriptionProblemReason}
                onChange={onChangePrescriptionProblemReason}
                type="text"
                placeholder="(예시)&#13;&#10;ㆍ 주민등록번호가 이상합니다.&#13;&#10;ㆍ 처방전 파일이 비어있습니다.&#13;&#10;ㆍ 다른 환자의 처방전입니다."
                height={120}
                fontSize="16px"
              />
            </Style.TextButtonArea>
            <Style.TextButtonArea style={{ marginBottom: '0' }}>
              <TextButtonWithTextArea
                isSelected={selectedId === 100}
                title="기타"
                onClick={() => selectExtraReject(100, extraReason)}
                key={String(100)}
                value={extraReason}
                onChange={onChangeExtraReason}
                type="text"
                placeholder="진료 거절 사유를 입력해주세요."
                height={120}
                fontSize="16px"
              />
            </Style.TextButtonArea>
          </>
        }
        modalFooter={
          <Button
            width="128px"
            padding="16px 12px"
            onClick={() => reject()}
            disableColor={COLOR['fill/medium']}
            backgroundColor={COLOR['fill/accent']}
            visible={buttonVisible}
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.Check2 color={COLOR['fill/white']} />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body2" color="fill/white">
                  예약 거절하기
                </Font>
              </Style.ButtonContainer>
            }
          />
        }
      />
    </>
  );
}

export default RejectTelepharmacyModal;
